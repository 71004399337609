import React, { useEffect, useState } from 'react';
import SolutionsGrid from './SolutionsGrid';
import PartnerSection from './PartnerSection';
import SolutionHeroSmall from './images/hero-banner-solution-small_2x.jpg';
import SolutionHeroLarge from './images/hero-banner-solution-large_2x.jpg';
import './solution.css';

export default function Solution() {
  return (
    <main>
      <>
        <section className="as-container-column as-columns--1up as-banner as-banner--top">
          <div className="column-item">
            <div className="as-banner-cont">
              <div className="as-banner-image as-banner-image--top">
                <img
                  sizes="(min-width:735px) 735w, 100vw"
                  src={SolutionHeroLarge}
                  alt="ZEM Engineering Solutions"
                  srcSet={`${SolutionHeroSmall} 735w, ${SolutionHeroLarge} 1440w`}
                />
              </div>
            </div>
          </div>{' '}
        </section>
        <section
          className="section section-hero background-alt"
          data-anim-scroll-group="Hero"
          data-component-list="Hero"
        >
          <div
            className="section-content row"
            data-analytics-section-engagement="name:hero"
          >
            <div className="hero-header large-centered">
              <h2 className="section-headline typo-hero-headline">
                Our Solutions.
              </h2>
              <h1 className="visuallyhidden">Our Solutions</h1>
              <p className="typo-hero-intro">
                At ZEM Engineering (M) Sdn Bhd, we provide tailored solutions
                for your engineering and connectivity needs, including advanced
                lightning protection systems and cutting-edge network cabling.
                Discover how we can enhance your projects below.
              </p>
            </div>
          </div>
        </section>
        <section
          className="section section-flex-tiles background-alt pl-alignment section-wide"
          data-anim-scroll-group="ProductTiles"
          data-component-list="ProductTiles"
        >
          {/* <div className="section-content row"> */}
          <SolutionsGrid />
          {/* </div> */}
        </section>

        <section className="section section-partner background-alt staggered-end">
          <PartnerSection />
        </section>
      </>
    </main>
  );
}
