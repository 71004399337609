import React from 'react';

const CablingTesting = () => {
  return (
    <div className="modal-project-sector">
      <h2 className="typo-modal-tile-headline">
        Cabling Installation. Management & Termination. Testing & Certification.
      </h2>

      <p className="feature-card-modal-copy typo-feature-card-modal-copy">
        ZEM specializes in the installation and maintenance of the cabling
        infrastructure necessary for data and communication networks. This
        includes structured cabling systems that support telecommunications,
        computer networks, and various forms of data transmission. Here’s a
        detailed breakdown of our services.
      </p>

      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Installation
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Cabling:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We install various types of cabling, such as twisted-pair cables
                (e.g., Cat5e, Cat6, Cat6a, Cat7), fiber optic cables, and
                coaxial cables, according to the design specifications and
                client requirements.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Cable Management:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We implement cable management systems to organize and secure
                cables, reducing clutter and preventing damage. This involves
                installing cable trays, conduits, and racks to ensure a clean
                and efficient setup.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Termination:
              </h5>
              <p className="typo-solution-card-modal-copy">
                Our team properly terminates cables with connectors and jacks,
                ensuring accurate and reliable connections for optimal network
                performance.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Testing and Certification
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Testing:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We conduct comprehensive testing of installed cabling systems to
                ensure they meet performance standards and are free from faults.
                Tests may include checks for signal strength, attenuation, and
                continuity.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Certification:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We provide certification of the cabling system, verifying that
                it meets industry standards and specifications. This often
                includes issuing detailed test reports and compliance
                documentation to clients.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Maintenance and Troubleshooting
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Routine Maintenance:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We perform regular maintenance to keep the cabling system in
                good working condition, proactively addressing any issues that
                arise.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Troubleshooting:
              </h5>
              <p className="typo-solution-card-modal-copy">
                Our team diagnoses and resolves problems related to the cabling
                infrastructure, such as connectivity issues, signal loss, or
                physical damage to cables, ensuring minimal downtime.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Upgrades and Expansion
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                System Upgrades:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We upgrade existing cabling systems to support new technologies
                or higher data speeds as network demands evolve, ensuring
                future-proof and scalable solutions.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Expansion:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We install additional cabling to accommodate new network
                devices, offices, or expansion projects, ensuring minimal
                disruption to existing services and maintaining network
                integrity.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Compliance and Standards
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Regulations:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We adhere to industry standards and codes set by organizations
                such as the Telecommunications Industry Association (TIA),
                International Organization for Standardization (ISO), and other
                relevant bodies to ensure high-quality installations.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Documentation:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We provide comprehensive documentation of the cabling system,
                including schematics, installation records, and maintenance
                logs, to support ongoing management and compliance.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Skills and Qualifications
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Technical Expertise:
              </h5>
              <p className="typo-solution-card-modal-copy">
                Our team has in-depth knowledge of network cabling technologies,
                standards, and installation practices, ensuring top-quality
                service delivery.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Certification:
              </h5>
              <p className="typo-solution-card-modal-copy">
                Our technicians hold certifications such as TM certifications,
                MCMC certifications, Certified Network Cable Installer (CNCI),
                or certifications from cabling manufacturers, demonstrating
                expertise and adherence to industry standards.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Experience:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We bring practical experience with a wide range of cabling
                installations and troubleshooting scenarios, which is essential
                for delivering high-quality work and maintaining reliable
                network infrastructures.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <p
        className="feature-card-modal-copy typo-feature-card-modal-copy"
        style={{ marginTop: '20px' }}
      >
        <strong>Summary:</strong> At ZEM Engineering, we play a critical role in
        establishing and maintaining the physical infrastructure for
        communication networks, ensuring that data and telecommunications
        systems operate efficiently and reliably. Our expertise in cabling
        installation, management, testing, and certification ensures robust and
        scalable network solutions for our clients.
      </p>
    </div>
  );
};

export default CablingTesting;
