import React from 'react';
import { NavComponent } from './NavComponent';
import SmallScreensNavbar from './SmallScreensNavbar';
import { useWindowWidthAndHeight } from './CustomHooks';
import './header.css';
import logo from './zem-engineering-logo.png';

const Header = () => {
  const [width] = useWindowWidthAndHeight(); // 只使用 width，去掉 height 的解构
  return (
    <header>
      <div className="zem-header-panel">
        <div className="global-logo"><img

                src={logo}
                alt="Zem Engineering"
              /></div>
        <div className="globalnav-content">
          {width > 734 ? (
            <NavComponent
              navClass="globalnav-large"
              linkClassName="globalnav-link"
            />
          ) : (
            <SmallScreensNavbar
              navClass="globalnav-small"
              linkClassName="globalnav-link"
            />
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
