import React from 'react';

const HomeMission = () => {
  return (
    <div className="modal-content-home-vision">
      <div className="feature-card-modal-content">
        <h1 className="typo-modal-tile-headline modal-headline headline">
          Mission
        </h1>
        <div className="modal-content-article ">
          <div className="article-content">
            <h5 class="typo-feature-card-modal-copy feature-card-modal-copy-highlight">
              Lightning Protection System:
            </h5>
            <p className="feature-card-modal-copy typo-feature-card-modal-copy">
              To deliver high-quality, customized lightning protection systems
              with integrity and professionalism, ensuring that all structures
              are fortified against the dangers of lightning strikes while
              maintaining the highest standards of service and compliance.
            </p>
          </div>
          <div className="article-content">
            <h5 class="typo-feature-card-modal-copy feature-card-modal-copy-highlight">
              Network Structure Cabling:
            </h5>
            <p className="feature-card-modal-copy typo-feature-card-modal-copy">
              To deliver superior network cabling services with precision and
              professionalism, ensuring robust and scalable infrastructure that
              meets the unique needs of our clients while upholding the highest
              standards of quality and service.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeMission;
