import React from 'react';

const projects = [
  {
    title: 'Radar Tower Sultan Mahmud Airport.',
    location: 'Kuala Terengganu, Terengganu',
  },
  {
    title: 'Radar Tower Sultan Ismail Petra Airport.',
    location: 'Kota Bharu, Kelantan.',
  },
  {
    title: 'Radar Tower KL International Airport, Terminal 1.',
    location: 'Kuala Lumpur.',
  },
  { title: 'Subang Hangar Skypark RAC.', location: 'Shah Alam, Selangor.' },
];

const SectorAviation = () => {
  return (
    <div className="modal-project-sector">
      <div className="feature-card-modal-content">
        <h2 className="typo-modal-tile-headline modal-headline headline">
          Aviation Sector
        </h2>
        <h4 className="typo-modal-project-sector-subhead">Airport</h4>
        <div className="modal-content-article">
          {projects.map((project, index) => (
            <div className="article-content" key={index}>
              <h5 className="typo-feature-card-modal-copy feature-card-modal-copy-highlight">
                {project.title}
              </h5>
              <p className="feature-card-modal-copy typo-feature-card-modal-copy">
                {project.location}
              </p>
            </div>
          ))}
        </div>
        <h6 className="typo-modal-noted">
          *More projects are being update soon.
        </h6>
      </div>
    </div>
  );
};

export default SectorAviation;
