export default function Footer() {
  return (
    <>
      <footer>
        <div id="zem-globalfooter">
          <div
            className="zem-gf-footer-call"
            style={{
              fontSize: '14px',
            }}
          >
            If you have any issues or additional questions, feel free to{' '}
            <a href="mailto: name@email.com">Email us</a> or call our customer
            support team at{' '}
            <a href="tel:+60378867936">
              <span className="focus">+603-7886 7936</span>
            </a>
            . We're here to help!
          </div>

          <div
            className="zem-gf-copyright"
            style={{
              fontSize: 'var(--font-footnote)',
            }}
          >
            Copyright © 2025 Zem Engineering (M) Sdn Bhd . All rights reserved.
          </div>
        </div>
      </footer>
    </>
  );
}
