import React from 'react';

const projects = [
  {
    title: 'Abbott Laboratories (M) Sdn. Bhd.',
    location: 'Bayan Lepas, Penang.',
  },
  {
    title: 'ECRL Section 10, Track Laying Base.',
    location: 'Gebeng, Pahang.',
  },
  {
    title: 'Eversendai Corporation Berhad.',
    location: 'Rawang, Selangor.',
  },
  {
    title: 'Flamingo Hotel.',
    location: 'Ampang, Selangor.',
  },
  {
    title: 'FFM Berhad.',
    location: 'Sungai Buloh, Selangor.',
  },
  {
    title: 'Flextronic Technology (PG) Sdn Bhd.',
    location: 'Pulau Pinang.',
  },
  {
    title: 'FGV Biotechnologies Sdn Bhd.',
    location: 'Kuantan, Pahang.',
  },
  {
    title: 'Intel PG7 & PG8.',
    location: 'Bayan Lepas, Penang.',
  },
  {
    title: 'Knowles Electronics (M) Sdn Bhd.',
    location: 'Bayan Lepas, Penang.',
  },
  {
    title: 'Kompleks RapidBus.',
    location: 'Cheras Selatan, Selangor.',
  },
  {
    title: 'Langkawi Port Sdn Bhd.',
    location: 'Langkawi, Kedah.',
  },
  {
    title: 'Mardi Government office.',
    location: 'Serdang, Selangor.',
  },
  {
    title: 'Nichicon (M) Sdn Bhd.',
    location: 'Bangi, Selangor.',
  },
  {
    title: 'NSK Micro Precision (M) Sdn Bhd.',
    location: 'Balakong, Selangor.',
  },
  {
    title: 'Sunway Palazzio.',
    location: 'Kuala Lumpur.',
  },
  {
    title: 'Parkside Flexibles (Asia) Sdn Bhd.',
    location: 'Shah Alam, Selangor.',
  },
  {
    title: 'Pusat Komunikasi Satelit, Kem Paya Jaras.',
    location: 'Shah Alam, Selangor.',
  },
  {
    title: 'Sapura AeroDome, Sapura Aerotree.',
    location: 'Shah Alam, Selangor.',
  },
  {
    title: 'Schenker Logistics (M) Sdn Bhd.',
    location: 'Bayan Lepas, Penang.',
  },
  {
    title: 'Sime Darby Auto Engineering.',
    location: 'Kulim, Kedah.',
  },
  {
    title: 'Sime Darby Oils Refinery Sdn Bhd.',
    location: 'Pasir Gudang, Johor.',
  },
  {
    title: 'Skuadron Pembangunan Perisian & Senggaraan.',
    location: 'Sungai Buloh, Selangor.',
  },
  {
    title: 'Sony EMCS (M) Sdn Bhd.',
    location: 'Bangi, Selangor.',
  },
  {
    title: 'The Italian Baker Sdn Bhd.',
    location: 'Klang, Selangor',
  },
  {
    title: 'Thomson Hospital.',
    location: 'Kota Damansara, Selangor.',
  },
  {
    title: 'Ye Chiu Non-Ferrous Metal (M) Sdn Bhd.',
    location: 'Pasir Gudang, Johor',
  },
];

const LpsHealthCheck = () => {
  return (
    <div className="modal-project-sector">
      <div className="feature-card-modal-content">
        <h2 className="typo-modal-tile-headline modal-headline headline">
          LPS Health Check
        </h2>
        <h4 className="typo-modal-project-sector-subhead">
          Fabrication, Production, and Operation Sector
        </h4>
        <div className="modal-content-article">
          {projects.map((project, index) => (
            <div className="article-content" key={index}>
              <h5 className="typo-feature-card-modal-copy feature-card-modal-copy-highlight">
                {project.title}
              </h5>
              <p className="feature-card-modal-copy typo-feature-card-modal-copy">
                {project.location}
              </p>
            </div>
          ))}
        </div>
        <h6 className="typo-modal-noted">
          *More projects are being update soon.
        </h6>
      </div>
    </div>
  );
};

export default LpsHealthCheck;
