import React, { useState, useEffect } from 'react';
import { NavComponent } from './NavComponent';

const SmallScreensNavbar = () => {
  const [translate, setTranslate] = useState(true);
  // 在背景上添加/移除滚动禁用的样式
  useEffect(() => {
    if (!translate) {
      document.body.style.overflow = 'hidden'; // 禁用滚动
    } else {
      document.body.style.overflow = 'auto'; // 恢复滚动
    }

    // 清理函数，确保在组件卸载时恢复样式
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [translate]);
  return (
    <>
      <button
        className="globalnav-menutrigger-button"
        onClick={() => setTranslate(!translate)}
      >
        {translate ? (
          <span className="menu">Menu</span>
        ) : (
          <span className="close">&times;</span>
        )}
      </button>
      <div
        id="globalnav-flyout"
        className={`${translate ? 'addTransiton' : 'removeTransition'}`}
      >
        <NavComponent
          navClass="globalnav-small"
          linkClassName="globalnav-link"
          onClick={() => setTranslate(true)} // 关闭侧边栏
        />
      </div>
    </>
  );
};

export default SmallScreensNavbar;
