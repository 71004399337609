import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import './Home.css';
import HomeVision from './HomeVision';
import HomeMission from './HomeMission';
import HomeGoalsObjectivesLightning from './HomeGoalsObjectivesLightning';
import HomeGoalsObjectivesNetwork from './HomeGoalsObjectivesNetwork';
// import smallBanner from './images/hero-banner-solution-small_2x.jpg';
import HomelargeBanner from './images/home-hero-banner.jpg';
import HomesmallBanner from './images/home-hero-banner.jpg';
const ListFeature = [
  {
    title: 'Lightning Surge Protection Devices',
    label: 'Airport',
    component: <HomeVision />,
  },
  {
    title: 'LPS Health Check',
    label: 'Commercial & Office',
    component: <HomeMission />,
  },
  {
    title: 'LPS Health Check',
    label: 'Commercial & Office',
    component: <HomeGoalsObjectivesLightning />,
  },
  {
    title: 'LPS Health Check',
    label: 'Commercial & Office',
    component: <HomeGoalsObjectivesNetwork />,
  },
];
const OurVision = ({ onOpen }) => (
  <div
    className="grid-item large-span-12 small-span-12 tile-body-blockquote-fullbleed tile-theme-dark with-bg-image tile-body-no-pad-top grid-item-satellite"
    data-component-list="TileOverlay WillChange"
    data-analytics-section-engagement="name:Our Vision"
  >
    <div className="tile tile-rounded tile-with-overlay">
      <div className="tile-content">
        <div className="tile-header">
          <h3 className="tile-headline typo-product-tile-headline">Vision</h3>
        </div>
        <div className="tile-body tile-body-blockquote">
          <div className="grid">
            <div className="blockquote-content large-span-12">
              <div className="tile-blockquote">
                <p className="typo-blockquote-copy">
                  Pioneering Advanced Lightning Protection and Network Cabling
                  Solutions for a Safer, Connected Future.
                </p>
                <p>
                  <button
                    onClick={() => onOpen(ListFeature[0].component)}
                    data-analytics-title="learn more"
                    className="button button-alt button-learn"
                  >
                    <span className="icon-copy">Learn more</span>
                    <span className="icon icon-after more">
                      <svg
                        class="tds-icon tds-icon-chevron-small-90 tds-icon--small"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="currentColor"
                          d="M4.975 2.75a.748.748 0 0 1 1.281-.53l5.25 5.264a.75.75 0 0 1 0 1.06L6.283 13.78a.75.75 0 1 1-1.062-1.06l4.695-4.706L5.194 3.28a.75.75 0 0 1-.219-.53"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </p>
              </div>
            </div>
            <div className="asset-content large-span-12">
              <figure
                className="overview-home-vision bg-image"
                role="img"
                aria-label="Our Vision"
              ></figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
const OurMission = ({ onOpen }) => (
  <div
    className="grid-item large-span-12 small-span-12 tile-body-blockquote-fullbleed tile-theme-dark with-bg-image tile-body-no-pad-top grid-item-satellite"
    data-component-list="TileOverlay WillChange"
    data-analytics-section-engagement="name:Our Mission"
  >
    <div className="tile tile-rounded tile-with-overlay">
      <div className="tile-content">
        <div className="tile-header">
          <h3 className="tile-headline typo-product-tile-headline">Mission</h3>
        </div>
        <div className="tile-body tile-body-blockquote">
          <div className="grid">
            <div className="blockquote-content large-span-12">
              <div className="tile-blockquote">
                <p className="typo-blockquote-copy">
                  Ensuring Safety and Scalability Through High-Quality Services.
                </p>
                <p>
                  <button
                    onClick={() => onOpen(ListFeature[1].component)}
                    data-analytics-title="learn more"
                    className="button button-alt button-learn"
                  >
                    <span className="icon-copy">Learn more</span>
                    <span className="icon icon-after more">
                      <svg
                        class="tds-icon tds-icon-chevron-small-90 tds-icon--small"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="currentColor"
                          d="M4.975 2.75a.748.748 0 0 1 1.281-.53l5.25 5.264a.75.75 0 0 1 0 1.06L6.283 13.78a.75.75 0 1 1-1.062-1.06l4.695-4.706L5.194 3.28a.75.75 0 0 1-.219-.53"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </p>
              </div>
            </div>
            <div className="asset-content large-span-12">
              <figure
                className="overview-home-mission bg-image"
                role="img"
                aria-label="Our Mission"
              ></figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
const HomeGoalsLightning = ({ onOpen }) => (
  <div
    className="grid-item large-span-6 small-span-12 tile-theme-dark with-bg-image grid-item-credentials"
    data-component-list="TileOverlay WillChange"
  >
    <div className="tile tile-rounded tile-with-overlay">
      <div className="tile-content">
        <div className="tile-header">
          <h3 className="tile-headline typo-product-tile-headline">
            Lightning Protection System
          </h3>
          <p className="tile-copy typo-subsection-subhead">
            Core Principles: Safety, Customer-Centric Solutions, Technology,
            Education, and Sustainability.
          </p>
          <p>
            <button
              onClick={() => onOpen(ListFeature[2].component)}
              data-analytics-title="learn more"
              aria-label="Learn more about Lightning Protection System"
              className="button button-alt button-learn"
            >
              <span className="icon-copy">Learn more</span>
              <span className="icon icon-after more">
                <svg
                  class="tds-icon tds-icon-chevron-small-90 tds-icon--small"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="currentColor"
                    d="M4.975 2.75a.748.748 0 0 1 1.281-.53l5.25 5.264a.75.75 0 0 1 0 1.06L6.283 13.78a.75.75 0 1 1-1.062-1.06l4.695-4.706L5.194 3.28a.75.75 0 0 1-.219-.53"
                  ></path>
                </svg>
              </span>
            </button>
          </p>
        </div>
        <div className="tile-body">
          <figure
            className="overview-lightning-collapsible-sections-bg bg-image"
            role="img"
            aria-label="Lightning Protection System"
          ></figure>
        </div>
      </div>
    </div>
  </div>
);
const HomeGoalsNetwork = ({ onOpen }) => (
  <div
    className="grid-item large-span-6 small-span-12 tile-theme-dark with-bg-image grid-item-synced"
    data-component-list="TileOverlay WillChange"
  >
    <div className="tile tile-rounded tile-with-overlay">
      <div className="tile-content">
        <div className="tile-header">
          <h3 className="tile-headline typo-product-tile-headline">
            Network Structure Cabling
          </h3>
          <p className="tile-copy typo-subsection-subhead">
            Key Principles of Our Services: Quality Installation, Customer
            Focus, Technology, Professionalism, Timely Delivery, and Ongoing
            Support.
          </p>
          <p>
            <button
              onClick={() => onOpen(ListFeature[3].component)}
              data-analytics-title="learn more"
              aria-label="Learn more about Network Structure Cabling"
              className="button button-alt button-learn"
            >
              <span className="icon-copy">Learn more</span>
              <span className="icon icon-after more">
                <svg
                  class="tds-icon tds-icon-chevron-small-90 tds-icon--small"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="currentColor"
                    d="M4.975 2.75a.748.748 0 0 1 1.281-.53l5.25 5.264a.75.75 0 0 1 0 1.06L6.283 13.78a.75.75 0 1 1-1.062-1.06l4.695-4.706L5.194 3.28a.75.75 0 0 1-.219-.53"
                  ></path>
                </svg>
              </span>
            </button>
          </p>
        </div>
        <div className="tile-body">
          <figure
            className="overview-cabling-collapsible-sections-bg bg-image"
            role="img"
            aria-label="Network Structure Cabling"
          ></figure>
        </div>
      </div>
    </div>
  </div>
);
export default function HomePage() {
  const [activeComponent, setActiveComponent] = useState(null);

  const handleShowComponent = (component) => {
    setActiveComponent(component);
  };

  const handleClose = () => setActiveComponent(null);

  useEffect(() => {
    const heroLockup = document.querySelector('.hero-lockup');
    if (heroLockup) {
      heroLockup.style.width = '100%'; // 设置初始宽度为100%
    }

    const handleScroll = () => {
      if (heroLockup) {
        // 确保元素存在
        const scrollY = window.scrollY;

        if (window.innerWidth > 768) {
          const newWidth = Math.max(70, 100 - scrollY / 20) + '%';
          heroLockup.style.width = newWidth;
        } else {
          heroLockup.style.width = '100%';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('load', handleScroll); // 也在加载时调用一次，以确保宽度正确

    // 清理函数，移除事件监听器
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // 空依赖数组，只在组件挂载和卸载时运行

  return (
    <>
      <section
        className="section home-hero "
        data-analytics-section-engagement="name:hero"
      >
        <div className="hero-lockup">
          <figure
            className="overview-zem-hero-2025"
            role="img"
            aria-label="Zem Engineering, Your Expert Partner."
          >
            {/* <img
              src={HomelargeBanner}
              alt="Zem Engineering, Your Expert Partner."
              className="responsive-image"
            /> */}
            <img
              sizes="(min-width:735px) 735w, 100vw"
              src={HomelargeBanner}
              alt="Zem Engineering, Your Expert Partner."
              className="responsive-image"
              srcSet={`${HomesmallBanner} 735w, ${HomelargeBanner} 1440w`}
            />
          </figure>
        </div>
        <div
          className="section-hero-header section-hero-header--ax-update-focus-visible section-content row"
          data-component-list="HeroCopy"
          data-anim-scroll-group="section - Hero"
        >
          <div className="section-hero-copy column large-12 large-centered medium-12 small-12">
            <h1
              className="typo-hero-eyebrow reveal"
              data-focus-expression='{"expression": "t - 10vh"}'
              data-focus-enabled-when="enhanced"
            >
              Zem Engineering
            </h1>
            <span
              className="typo-hero-headline reveal"
              data-focus-expression='{"expression": "t - 15vh"}'
              data-focus-enabled-when="enhanced"
            >
              Your Expert Partner.
            </span>
            <p className="section-hero-copy-body large-12 medium-11 small-12 typo-hero-intro reveal">
              We pride ourselves on our team of experienced and qualified
              professional partners, along with a dedicated staff committed to
              ensuring your complete satisfaction. Our collective expertise and
              teamwork enable us to deliver exceptional services tailored to
              your needs.
            </p>
            <div className="section-hero-cta-wrapper reveal"></div>
          </div>
        </div>
      </section>
      <section className="section section-strengths">
        <article
          className="grid-wrapper section-strengths"
          aria-label="Earthing and lightning Protection"
          data-anim-scroll-group="section - strengths"
        >
          <div className="grid">
            <div
              className="grid-item large-span-6 small-span-12 tile-theme-dark with-bg-image grid-item-collapsible-sections"
              data-analytics-section-engagement="name:collapsible sections"
              data-tile-name="collapsible-sections"
            >
              <div className="tile tile-rounded">
                <div className="tile-content">
                  <div className="tile-header">
                    <h3 className="tile-headline typo-product-tile-headline">
                      Earthing & lightning Protection
                    </h3>
                    <p className="tile-copy typo-subsection-subhead">
                      Zem is a leading global provider of earthing & lightning
                      protection solutions to industry, commerce and utilities,
                      covering MS, JKR, ST, IEC/EN, NFC, UL and NFPA projects.
                    </p>
                  </div>
                  <div
                    className="tile-body"
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <figure
                      className="overview-earthing-collapsible-sections-bg bg-image"
                      data-anim-lazy-image-download-complete=""
                      aria-label="Earthing & lightning protection"
                    ></figure>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="grid-item large-span-6 small-span-12 tile-theme-dark with-bg-image grid-item-highlight"
              data-component-list="TileOverlay WillChange"
              data-analytics-section-engagement="name:Seamless Network Connectivity"
              data-tile-name="Seamless Network Connectivity"
            >
              <div className="tile tile-rounded tile-with-overlay">
                <div className="tile-content">
                  <div className="tile-header">
                    <h3 className="tile-headline typo-product-tile-headline">
                      Seamless Network Connectivity
                    </h3>
                    <p className="tile-copy typo-subsection-subhead">
                      Provide a safer, automated, informed, and customized
                      experience to your network users, and gain actionable
                      business and operational insights.
                    </p>
                  </div>
                  <div
                    className="tile-body"
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <figure
                      className="overview-seamless-collapsible-sections-bg bg-image"
                      data-anim-lazy-image-download-complete=""
                      aria-label="Seamless Network Connectivity"
                    ></figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </section>
      <section className="section section-target">
        <article
          className="grid-wrapper"
          aria-label="Section our vision and mission messages"
          data-anim-scroll-group="section - vision mission"
        >
          <div class="section-intro">
            <h2 class="typo-section-intro-headline section-intro-headline">
              Discover Our Vision and Mission
            </h2>
          </div>
          <div className="grid">
            <OurVision onOpen={handleShowComponent} />
            <OurMission onOpen={handleShowComponent} />
          </div>
        </article>
      </section>

      <section className="section section-goals">
        <article
          className="grid-wrapper section-goals"
          aria-label="Goals and Objectives Section"
          data-anim-scroll-group="section - Goals Objectives"
        >
          <div className="section-intro">
            <h2 className="typo-section-intro-headline section-intro-headline">
              Goals & Objectives
            </h2>
          </div>
          <div className="grid">
            <HomeGoalsLightning onOpen={handleShowComponent} />
            <HomeGoalsNetwork onOpen={handleShowComponent} />
          </div>
        </article>
      </section>
      <section className="section section-commitment">
        <article
          className="grid-wrapper"
          aria-label="Our Commitment to Client Satisfaction"
          data-anim-scroll-group="section - commitment"
        >
          <div
            className="section-intro"
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <h2 className="typo-section-intro-headline section-intro-headline">
              Our Commitment to Client Satisfaction
            </h2>
            <p className="typo-section-copy section-intro-copy">
              At Zem, our commitment to client satisfaction is at the core of
              everything we do.
            </p>
            <p className="typo-section-copy section-intro-copy">
              As what we do for client
            </p>
          </div>
          <div className="grid">
            <div className="grid-item large-span-4 medium-span-12 tile-body-no-pad-bottom grid-item-writing">
              <div className="tile tile-rounded">
                <div className="tile-content">
                  <div className="tile-header">
                    <h3 className="tile-headline typo-subsection-headline">
                      Expert Team Members
                    </h3>
                    <p className="tile-copy typo-subsection-subhead">
                      We take pride in assembling a diverse and highly skilled.
                    </p>
                  </div>
                  <div className="tile-body">
                    <figure
                      className="overview-expert-team-member scalable use-width large-centered"
                      role="img"
                      aria-label="Expert Team Members"
                    ></figure>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-item large-span-4 medium-span-12 tile-body-no-pad-bottom grid-item-images">
              <div className="tile tile-rounded">
                <div className="tile-content">
                  <div className="tile-header">
                    <h3 className="tile-headline typo-subsection-headline">
                      Fastest Customer Service
                    </h3>
                    <p className="tile-copy typo-subsection-subhead">
                      We pride ourselves on providing the fastest customer
                      service industry.
                    </p>
                  </div>
                  <div className="tile-body">
                    <figure
                      className="overview-fast-customer-service scalable use-width large-centered"
                      role="img"
                      aria-label="Fastest Customer Service"
                    ></figure>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-item large-span-4 medium-span-12 tile-body-no-pad-bottom grid-item-siri">
              <div className="tile tile-rounded">
                <div className="tile-content">
                  <div className="tile-header">
                    <h3 className="tile-headline typo-subsection-headline">
                      Reasonable Pricing
                    </h3>
                    <p className="tile-copy typo-subsection-subhead">
                      We believe in providing reasonable pricing that offers
                      exceptional.
                    </p>
                  </div>
                  <div className="tile-body">
                    <figure
                      className="overview-reasonable-pricing scalable use-width large-centered"
                      role="img"
                      aria-label="Reasonable Pricing"
                    ></figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </section>
      {activeComponent && (
        <Modal
          show={true}
          onHide={handleClose}
          className="modal-page-overlay feature-card-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="modal-content-container">
            <Modal.Body className="modal-content-wrapper">
              <div className="feature-card-modal-content">
                {activeComponent}
              </div>
            </Modal.Body>
          </div>
        </Modal>
      )}
    </>
  );
}
