import React from 'react';

const projects = [
  {
    title: 'Grand Global Heights.',
    location: 'Petaling Jaya, Selangor. (2024 On-going)',
  },
  {
    title: 'LakeFront Residence.',
    location: 'Cyberjaya, Selangor.',
  },
  {
    title: 'M Arisa, M Nova, M Oscar, M Vertica.',
    location: 'Kuala Lumpur.',
  },
  { title: 'PPA1M.', location: 'Putrajaya.' },
  { title: 'PPA1M Sofiya.', location: 'Desa Park City, Kuala Lumpur.' },
  { title: 'The Link 2.', location: 'Bukit Jalil, Kuala Lumpur.' },
  { title: 'KL Trillion.', location: 'Kuala Lumpur.' },
];

const SectorProperty = () => {
  return (
    <div className="modal-project-sector">
      <div className="feature-card-modal-content">
        <h2 className="typo-modal-tile-headline modal-headline headline">
          Real Estate
        </h2>
        <h4 className="typo-modal-project-sector-subhead">Residential</h4>
        <div className="modal-content-article">
          {projects.map((project, index) => (
            <div className="article-content" key={index}>
              <h5 className="typo-feature-card-modal-copy feature-card-modal-copy-highlight">
                {project.title}
              </h5>
              <p className="feature-card-modal-copy typo-feature-card-modal-copy">
                {project.location}
              </p>
            </div>
          ))}
        </div>
        <h6 className="typo-modal-noted">
          *More projects are being update soon.
        </h6>
      </div>
    </div>
  );
};

export default SectorProperty;
