import React from 'react';

const NetworkCard = ({ card }) => (
  <div className="project-card" tabIndex="-1">
    <div className="card-modifier card-padding theme-dark fixed-width-network">
      <div className="card-viewport-content">
        <div className="feature-card-content">
          <div className="feature-card-copy">
            <p className="typo-feature-card-label feature-card-label">
              {card.label}
            </p>
            <h3 className="typo-card-headline feature-card-headline">
              {card.headline}
            </h3>
            <h4 className="typo-card-subheadline feature-card-headline">
              {card.subheadline}
            </h4>
          </div>
          <figure className="feature-card-image-container image-bottom-center">
            <picture
              className={`overview-consider-${card.label
                .toLowerCase()
                .replace(/\s+/g, '-')}`}
            >
              <source
                srcSet={`${
                  card.imgSrcSet.small
                } 1x, ${card.imgSrcSet.small.replace('.jpg', '_2x.jpg')} 2x`}
                media="(max-width:734px)"
              />
              <source
                srcSet={`${
                  card.imgSrcSet.medium
                } 1x, ${card.imgSrcSet.medium.replace('.jpg', '_2x.jpg')} 2x`}
                media="(max-width:1068px)"
              />
              <source
                srcSet={`${
                  card.imgSrcSet.large
                } 1x, ${card.imgSrcSet.large.replace('.jpg', '_2x.jpg')} 2x`}
                media="(max-width:1440px)"
              />
              <source
                srcSet={`${
                  card.imgSrcSet.xlarge
                } 1x, ${card.imgSrcSet.xlarge.replace('.jpg', '_2x.jpg')} 2x`}
                media="(min-width:0px)"
              />
              <img src={card.imgSrc} alt="" />
            </picture>
            <noscript>
              <picture
                className={`overview-consider-${card.label
                  .toLowerCase()
                  .replace(/\s+/g, '-')}`}
              >
                <source
                  srcSet={`${
                    card.imgSrcSet.small
                  } 1x, ${card.imgSrcSet.small.replace('.jpg', '_2x.jpg')} 2x`}
                  media="(max-width:734px)"
                />
                <source
                  srcSet={`${
                    card.imgSrcSet.medium
                  } 1x, ${card.imgSrcSet.medium.replace('.jpg', '_2x.jpg')} 2x`}
                  media="(max-width:1068px)"
                />
                <source
                  srcSet={`${
                    card.imgSrcSet.large
                  } 1x, ${card.imgSrcSet.large.replace('.jpg', '_2x.jpg')} 2x`}
                  media="(max-width:1440px)"
                />
                <source
                  srcSet={`${
                    card.imgSrcSet.xlarge
                  } 1x, ${card.imgSrcSet.xlarge.replace('.jpg', '_2x.jpg')} 2x`}
                  media="(min-width:0px)"
                />
                <img src={card.imgSrc} alt="" />
              </picture>
            </noscript>
          </figure>
        </div>
      </div>
    </div>
  </div>
);
export default NetworkCard;
