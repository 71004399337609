import React from 'react';
const projects = [
  { title: 'Four Seasons Place.', location: 'Kuala Lumpur.' },
  {
    title: 'Beacon Hospital 2',
    location: 'Petaling Jaya, Selangor.',
  },
  { title: 'Eko Cheras.', location: 'Kuala Lumpur.' },
  {
    title: 'KL Sentral Lot G NU Sentral.',
    location: 'Kuala Lumpur.',
  },
  {
    title: 'Palace of Golden Horses Extension',
    location: 'Seri Kembangan, Selangor.',
  },
  { title: 'Shaftsbury.', location: 'Putrajaya.' },
  { title: 'Sunsuria.', location: 'Shah Alam, Selangor.' },
  { title: 'The Hub SS2.', location: 'Petaling Jaya, Selangor.' },
  {
    title: 'The Ruma Hotel & Residences.',
    location: 'Kuala Lumpur.',
  },
  {
    title: 'WKL Hotel.',
    location: 'Kuala Lumpur.',
  },
];
const SectorPrivate = () => {
  return (
    <div className="modal-project-sector">
      <div className="feature-card-modal-content">
        <h2 className="typo-modal-tile-headline modal-headline headline">
          Private Sector
        </h2>
        <h4 className="typo-modal-project-sector-subhead">
          Commercial & Office
        </h4>
        <div className="modal-content-article">
          {projects.map((project, index) => (
            <div className="article-content" key={index}>
              <h5 className="typo-feature-card-modal-copy feature-card-modal-copy-highlight">
                {project.title}
              </h5>
              <p className="feature-card-modal-copy typo-feature-card-modal-copy">
                {project.location}
              </p>
            </div>
          ))}
        </div>
        <h6 className="typo-modal-noted">
          *More projects are being update soon.
        </h6>
      </div>
    </div>
  );
};

export default SectorPrivate;
