import React from 'react';

const LpsHealthCheck = () => {
  return (
    <div className="modal-project-sector">
      <h2 className="typo-modal-tile-headline">LPS Health Check.</h2>

      <p className="feature-card-modal-copy typo-feature-card-modal-copy">
        Lightning Protection Health Check Services are specialized assessments
        conducted to ensure that a building's or structure’s lightning
        protection system (LPS) is functioning correctly and providing the
        intended level of safety. These services are crucial for maintaining the
        integrity and effectiveness of LPS over time. Below is a detailed
        overview of our Lightning Protection Health Check Services:-
      </p>

      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Comprehensive System Inspection
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Visual Inspection:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We examine all visible components of the lightning protection
                system, including lightning rods (air terminals), grounding
                systems, bonding connections, and down conductors, for signs of
                physical damage, corrosion, or wear.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Component Assessment:
              </h5>
              <p className="typo-solution-card-modal-copy">
                Our experts assess the condition of critical components such as
                surge protectors, grounding electrodes, and connectors to ensure
                they are in optimal working condition.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Testing and Evaluation:
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Continuity Testing:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We measure the electrical continuity of the grounding system to
                confirm that all parts are properly connected, ensuring there
                are no breaks or loose connections.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Ground Resistance Testing:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We assess the resistance of the grounding system to ensure it is
                within acceptable limits. High ground resistance can compromise
                the system's effectiveness, so this test is crucial.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Compliance and Standards Verification
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Regulatory Compliance:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We verify that the lightning protection system adheres to
                current standards and regulations, such as those set by the
                Energy Commission (Suruhanjaya Tenaga), International
                Electrotechnical Commission (IEC), or other local codes.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Documentation Review:
              </h5>
              <p className="typo-solution-card-modal-copy">
                Our team reviews the installation and maintenance documentation
                to ensure they are up-to-date and reflect any changes or
                upgrades made to the system.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            System Performance Analysis
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Efficiency Evaluation:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We assess the overall performance of the lightning protection
                system, including its effectiveness in mitigating lightning
                strikes and protecting the structure.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Surge Protection Review:
              </h5>
              <p className="typo-solution-card-modal-copy">
                Our experts evaluate the performance of any surge protection
                devices integrated into the system to ensure they are
                functioning correctly.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Lightning Risk Assessment Calculation:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We conduct a detailed assessment to evaluate the potential risks
                associated with lightning strikes to a structure or site. This
                process helps determine the effectiveness of existing or
                proposed lightning protection measures, ensuring compliance with
                safety standards and identifying vulnerabilities.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Reporting and Recommendations
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Health Check Report:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We provide a comprehensive report detailing the findings from
                the inspection and testing. This includes information on the
                condition of the system, any identified issues, and
                recommendations for improvements or repairs.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Action Plan:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We develop an action plan to address any deficiencies or
                required upgrades, including timelines and cost estimates for
                repairs or enhancements.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Maintenance and Follow-Up
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Routine Maintenance:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We offer ongoing maintenance services to address issues
                identified during the health check and to ensure the system
                remains in optimal condition.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Follow-Up Inspections:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We schedule periodic follow-up inspections to monitor the
                system’s performance and address any new concerns that may
                arise.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Skills and Qualifications
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Expertise:
              </h5>
              <p className="typo-solution-card-modal-copy">
                Our technicians possess specialized knowledge in lightning
                protection systems and are well-versed in relevant standards and
                codes.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Certification:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We ensure that our team members have the relevant certifications
                in lightning protection system inspection and testing.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Experience:
              </h5>
              <p className="typo-solution-card-modal-copy">
                Our professionals bring practical experience with various types
                of lightning protection systems, which is crucial for accurate
                assessment and effective recommendations.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <p
        className="feature-card-modal-copy typo-feature-card-modal-copy"
        style={{ marginTop: '20px' }}
      >
        <strong>Summary:</strong> Lightning Protection Health Check Services are
        essential for maintaining the safety and effectiveness of lightning
        protection systems. Regular health checks help ensure that the system
        can reliably protect structures from lightning strikes and associated
        electrical surges, reducing the risk of damage and enhancing overall
        safety.
      </p>
    </div>
  );
};

export default LpsHealthCheck;
