import { useNavigate } from 'react-router-dom';

export const NavComponent = ({ navClass, linkClassName, onClick }) => {
  const navigate = useNavigate();

  const handleNavigation = (section) => {
    // 确保路由路径是正确的
    const path =
      section === 'Home' ? '/' : `/${section.toLowerCase().replace(' ', '-')}`;
    navigate(path);
    if (typeof onClick === 'function') {
      onClick(); // 仅在 onClick 是函数时调用
    }
  };

  return (
    <nav className={navClass}>
      <ul className="globalnav-submenu-trigger-group">
        {['Home', 'About Zem', 'Solutions', 'Our Works', 'Contact Us'].map(
          (section) => (
            <li className="globalnav-submenu-trigger-item" key={section}>
              <button
                onClick={() => handleNavigation(section)}
                className={linkClassName}
              >
                <span>{section}</span>
                <svg
                  className="tds-icon tds-icon-chevron-small-90 tds-icon--small"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="currentColor"
                    d="M4.975 2.75a.748.748 0 0 1 1.281-.53l5.25 5.264a.75.75 0 0 1 0 1.06L6.283 13.78a.75.75 0 1 1-1.062-1.06l4.695-4.706L5.194 3.28a.75.75 0 0 1-.219-.53"
                  ></path>
                </svg>
              </button>
            </li>
          ),
        )}
      </ul>
    </nav>
  );
};
