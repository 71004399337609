import React from 'react';

const projects = [
  {
    title: 'DASH Highway CA1.',
    location: 'Selangor.',
  },
  {
    title: 'ECRL Track Laying Base.',
    location: 'Kuantan.',
  },
  {
    title: 'Kelana Jaya LRT Extension, Package A2.',
    location: 'Petaling Jaya, Selangor.',
  },
  { title: 'LRT3, Johan Setia Depot TD1 & TD2.', location: 'Klang Valley' },
  { title: 'MRT 1 Stations.', location: 'Phileo Damansara, Kuala Lumpur' },
  {
    title: 'MRT 2, SY204 - Stray Current Continuity Cable.',
    location: 'Klang Valley',
  },
];

const SectorRailway = () => {
  return (
    <div className="modal-project-sector">
      <div className="feature-card-modal-content">
        <h2 className="typo-modal-tile-headline modal-headline headline">
          Infrastructure Industries
        </h2>
        <h4 className="typo-modal-project-sector-subhead">Railway</h4>
        <div className="modal-content-article">
          {projects.map((project, index) => (
            <div className="article-content" key={index}>
              <h5 className="typo-feature-card-modal-copy feature-card-modal-copy-highlight">
                {project.title}
              </h5>
              <p className="feature-card-modal-copy typo-feature-card-modal-copy">
                {project.location}
              </p>
            </div>
          ))}
        </div>
        <h6 className="typo-modal-noted">
          *More projects are being update soon.
        </h6>
      </div>
    </div>
  );
};

export default SectorRailway;
