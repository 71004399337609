import React from 'react';
import './About.css';
import AboutHeroSmall from './images/hero-banner-about-small_2x.jpg';
import AboutHeroLarge from './images/hero-banner-about-large_2x.jpg';

import ExpertiseImgSmall from './images/expertise-image.small_2x.png';
import ExpertiseImgLarge from './images/expertise-image.large_2x.png';

import RelationshipsImgSmall from './images/relationships-image.small_2x.png';
import RelationshipsImgLarge from './images/relationships-image.large_2x.png';

import VenturesImgSmall from './images/ventures-image.small_2x.png';
import VenturesImgLarge from './images/ventures-image.large_2x.png';

import QualitymgSmall from './images/quality-image.small_2x.png';
import QualityImgLarge from './images/quality-image.large_2x.png';

const ourstory = [
  {
    articleClass: 'our-expertise',
    title: 'Our Expertise',
    decription:
      'Our team comprises experienced and qualified professionals, supported by diligent and certified staff with a proven track record in their respective fields. This collective expertise enables us to execute projects with exceptional quality and reliability.',
    imageSmall: ExpertiseImgSmall,
    imageLarge: ExpertiseImgLarge,
  },
  {
    articleClass: 'client-relationships',
    title: 'Client Relationships',
    decription:
      'As we have grown, Zem Engineering has expanded its portfolio and established trusted relationships with government agencies, commercial enterprises, and corporate clients. Our commitment to personalized service and dedication to excellence has been central to our success and longstanding client partnerships.',
    imageSmall: RelationshipsImgSmall,
    imageLarge: RelationshipsImgLarge,
  },
  {
    articleClass: 'new-ventures',
    title: 'New Ventures',
    decription:
      'In response to evolving market demands and to better serve our clients, we are excited to announce our expansion into Network Structure Cabling, specifically targeting the burgeoning Data-Center market. This new direction aligns with our strategic goals and leverages our engineering expertise to meet the increasing demand for sophisticated, reliable, and scalable data infrastructure solutions.',
    imageSmall: VenturesImgSmall,
    imageLarge: VenturesImgLarge,
  },
  {
    articleClass: 'commitment-quality',
    title: 'Commitment to Quality',
    decription:
      'Our commitment remains steadfast in delivering superior performance and maintaining the highest standards of quality and service as we embark on this new venture.',
    imageSmall: QualitymgSmall,
    imageLarge: QualityImgLarge,
  },
];
export default function AboutUs() {
  return (
    <>
      <section className="as-container-column as-columns--1up as-banner as-banner--top">
        <div className="column-item">
          <div className="as-banner-cont">
            <div
              className="as-banner-image as-banner-image--top"
              style={{ backgroundImage: `url(${AboutHeroLarge})` }}
            >
              <img
                sizes="(min-width:735px) 735w, 100vw"
                src={AboutHeroLarge}
                alt=""
                srcSet={`${AboutHeroSmall} 735w, ${AboutHeroLarge} 1440w`}
                className="as-image-speculativedownload"
              />
            </div>
          </div>
          <div className="as-banner-content"></div>
        </div>
      </section>
      <section
        className="section section-hero background-alt"
        data-anim-scroll-group="Hero"
        data-component-list="Hero"
      >
        <div
          className="section-content row"
          data-analytics-section-engagement="name:hero"
        >
          <div className="hero-header large-centered">
            <h2 className="section-headline typo-hero-headline">
              ZEM Overview
            </h2>
            <h1 className="visuallyhidden">ZEM Overview.</h1>
            <p className="typo-hero-intro">
              Founded in July 2011, ZEM Engineering (M) Sdn Bhd specializes in
              Earthing and Lightning Protection Systems, as well as Surge
              Protection Devices. Over the years, we have established a strong
              reputation for providing high-quality engineering and contracting
              services in both the public and private sectors.
            </p>
          </div>
        </div>
      </section>

      <section className="section-about-content as-container-column as-columns--1up as-columns-bg about-zem-bg">
        <div className="column-item">
          <div className="content-banner-row">
            {ourstory.map((story, index) => (
              <div
                className={`grid as-tile-wrapper as-tile--1up as-fluid ${story.articleClass}`}
                key={index}
              >
                <div className="grid-item large-span-12 medium-span-12 small-span-12">
                  <div className="as-tile as-tile-rounded">
                    <div className="as-tile-content as-center">
                      <h2 className="typo-headline-reduced">{story.title}</h2>
                      <div className="as-richtext">
                        <p>{story.decription}</p>
                      </div>
                    </div>
                    <picture className="as-tile-image justify-content-center">
                      <source
                        srcSet={story.imageSmall}
                        media="(max-width: 734px)"
                      />
                      <img
                        src={story.imageLarge}
                        alt=""
                        width="980"
                        style={{
                          '--width-large': '980px',
                          '--width-small': '480px',
                        }}
                        height="370"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
