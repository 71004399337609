import React from 'react';

const LightningSurgeProtectionDevices = () => {
  return (
    <div className="modal-project-sector">
      <h2 className="typo-modal-tile-headline">
        Earthing System, Lightning Protection System & Lightning Surge
        Protection Devices
      </h2>

      <p className="feature-card-modal-copy typo-feature-card-modal-copy">
        ZEM Engineering is a specialized professional service provider dedicated
        to designing, installing, and maintaining systems that protect
        buildings, structures, and people from lightning strikes and related
        electrical surges. Our work is essential in minimizing damage and
        ensuring safety during thunderstorms. Below is a detailed overview of
        our services:
      </p>

      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Assessment and Design
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Risk Assessment:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We evaluate the specific needs of a property or structure,
                considering factors such as height, location, and construction
                materials to determine potential risks from lightning strikes
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                System Design:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We develop a customized lightning protection system plan that
                may include lightning rods, grounding systems, and surge
                protection devices to ensure comprehensive protection.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Installation
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Lightning Rods and Air Terminals:
              </h5>
              <p className="typo-solution-card-modal-copy">
                Our experts install rods or other devices designed to intercept
                lightning strikes and safely channel the electrical energy into
                the ground.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Grounding Systems:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We implement grounding systems that safely disperse electrical
                energy from a lightning strike into the earth, minimizing the
                risk of damage or injury.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Bonding:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We ensure all metallic components of a structure are properly
                bonded to the grounding system, preventing electrical surges
                from causing harm or damage.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Maintenance and Inspection
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Routine Inspections:
              </h5>
              <p className="typo-solution-card-modal-copy">
                Regular inspections are conducted to ensure the lightning
                protection system functions correctly and remains in good
                condition.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Repairs and Upgrades:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We address any issues with the system, including replacing
                damaged components or upgrading systems to meet current
                standards and codes.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Compliance and Standards
          </h5>
          <div className="">
            <div className="">
              <ul>
                <li>
                  <h5 className="typo-solution-card-modal-copy-highlight">
                    Regulations:
                  </h5>
                  <p className="typo-solution-card-modal-copy">
                    We ensure that all installations comply with local,
                    national, and international lightning protection standards
                    and codes, such as those set by the Energy Commission
                    (Suruhanjaya Tenaga), International Electrotechnical
                    Commission (IEC), or other relevant authorities.
                  </p>
                </li>
                <li>
                  <h5 className="typo-solution-card-modal-copy-highlight">
                    Documentation:
                  </h5>
                  <p className="typo-solution-card-modal-copy">
                    We provide comprehensive documentation and certification to
                    confirm that the system meets all safety standards and
                    regulations.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Consultation and Education
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Client Education:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We educate clients on the importance of lightning protection and
                provide guidance on maintaining their systems for long-term
                effectiveness.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Consultation Services:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We offer expert advice on best practices for lightning
                protection, including integration with other building systems
                for optimal safety and functionality.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Skills and Qualifications
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Technical Expertise:
              </h5>
              <p className="typo-solution-card-modal-copy">
                Our team has a deep understanding of electrical systems,
                lightning behavior, and advanced protection technologies.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Certification:
              </h5>
              <p className="typo-solution-card-modal-copy">
                We require specialized training and certification in lightning
                protection systems to ensure high-quality service delivery.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Experience:
              </h5>
              <p className="typo-solution-card-modal-copy">
                Our professionals bring extensive experience in the installation
                and maintenance of lightning protection systems, which is vital
                for ensuring safety and system reliability.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <p
        className="feature-card-modal-copy typo-feature-card-modal-copy"
        style={{ marginTop: '20px' }}
      >
        <strong>Conclusion:</strong> At ZEM, we play a key role in safeguarding
        structures and people from the potentially devastating effects of
        lightning. Our comprehensive services ensure both the safety and
        longevity of properties, providing peace of mind during thunderstorms.
      </p>
    </div>
  );
};

export default LightningSurgeProtectionDevices;
