import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.css';

// * Style Sheet
// ====================================================================================== * /
import './App.css';
import './global-main.built.css';
// import './typography.css';

import Header from './layout/Header/';
import HomePage from './pages/HomePage';
import AboutUs from './pages/AboutUs';
import Solution from './pages/Solution/';
import OurWork from './pages/OurWork';
import ContactUs from './pages/Contact';
import Footer from './layout/Footer/';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about-zem" element={<AboutUs />} />
            <Route path="/solutions" element={<Solution />} />
            <Route path="/our-works" element={<OurWork />} />
            <Route path="/contact-us" element={<ContactUs />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
