import React, { useState } from 'react';
import safetyImgSmall from './images/goals-objectives/lightning_safety_effectiveness-small.jpg';
import safetyImgSmall2x from './images/goals-objectives/lightning_safety_effectiveness-small_2x.jpg';
import safetyImgMedium from './images/goals-objectives/lightning_safety_effectiveness-medium.jpg';
import safetyImgMedium2x from './images/goals-objectives/lightning_safety_effectiveness-medium_2x.jpg';
import safetyImgLarge from './images/goals-objectives/lightning_safety_effectiveness-large.jpg';
import safetyImgLarge2x from './images/goals-objectives/lightning_safety_effectiveness-large_2x.jpg';

import CustomerImgSmall from './images/goals-objectives/lightning_customer_centric_approach-small.jpg';
import CustomerImgSmall2x from './images/goals-objectives/lightning_customer_centric_approach-small_2x.jpg';
import CustomerImgMedium from './images/goals-objectives/lightning_customer_centric_approach-medium.jpg';
import CustomerImgMedium2x from './images/goals-objectives/lightning_customer_centric_approach-medium_2x.jpg';
import CustomerImgLarge from './images/goals-objectives/lightning_customer_centric_approach-large.jpg';
import CustomerImgLarge2x from './images/goals-objectives/lightning_customer_centric_approach-large_2x.jpg';

import TechnologicalImgSmall from './images/goals-objectives/lightning_technological_advancement-small.jpg';
import TechnologicalImgSmall2x from './images/goals-objectives/lightning_technological_advancement-small_2x.jpg';
import TechnologicalImgMedium from './images/goals-objectives/lightning_technological_advancement-medium.jpg';
import TechnologicalImgMedium2x from './images/goals-objectives/lightning_technological_advancement-medium_2x.jpg';
import TechnologicalImgLarge from './images/goals-objectives/lightning_technological_advancement-large.jpg';
import TechnologicalImgLarge2x from './images/goals-objectives/lightning_technological_advancement-large_2x.jpg';

import EducationImgSmall from './images/goals-objectives/lightning_education_awareness-small.jpg';
import EducationImgSmall2x from './images/goals-objectives/lightning_education_awareness-small_2x.jpg';
import EducationImgMedium from './images/goals-objectives/lightning_education_awareness-medium.jpg';
import EducationImgMedium2x from './images/goals-objectives/lightning_education_awareness-medium_2x.jpg';
import EducationImgLarge from './images/goals-objectives/lightning_education_awareness-large.jpg';
import EducationImgLarge2x from './images/goals-objectives/lightning_education_awareness-large_2x.jpg';

import SustainableImgSmall from './images/goals-objectives/lightning_sustainable_practices-small.jpg';
import SustainableImgSmall2x from './images/goals-objectives/lightning_sustainable_practices-small_2x.jpg';
import SustainableImgMedium from './images/goals-objectives/lightning_sustainable_practices-medium.jpg';
import SustainableImgMedium2x from './images/goals-objectives/lightning_sustainable_practices-medium_2x.jpg';
import SustainableImgLarge from './images/goals-objectives/lightning_sustainable_practices-large.jpg';
import SustainableImgLarge2x from './images/goals-objectives/lightning_sustainable_practices-large_2x.jpg';

const HomeGoalsObjectivesLightning = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = (event) => {
    setIsLoaded(true);
    // 其他逻辑，例如跟踪图像加载或更新状态
    console.log('Image loaded', event);
  };

  return (
    <div className="modal-content-home-goals-objectives-lightning">
      <div className="feature-card-modal-content">
        <h1 className="typo-modal-tile-headline modal-headline headline">
          Lightning Protection System
        </h1>
        <div className="modal-content-article">
          <div className="article-content safety-effectiveness">
            <h5 className="typo-feature-card-modal-copy feature-card-modal-copy-highlight">
              Safety and Effectiveness.
            </h5>
            <p className="feature-card-modal-copy typo-feature-card-modal-copy">
              Implement solutions that ensure maximum protection and
              reliability.
            </p>
            <div className="image-container">
              <picture className="overview-consider-modals-environment-modal-zero responsive-image loaded">
                <source
                  srcSet={`${safetyImgSmall} 1x, ${safetyImgSmall2x} 2x`}
                  media="(max-width:734px)"
                />
                <source
                  srcSet={`${safetyImgMedium} 1x, ${safetyImgMedium2x} 2x`}
                  media="(max-width:1068px)"
                />
                <source
                  srcSet={`${safetyImgLarge} 1x, ${safetyImgLarge2x} 2x`}
                  media="(min-width:0px)"
                />
                <img
                  src={safetyImgLarge}
                  onLoad={handleImageLoad}
                  alt="Safety and Effectiveness"
                />
              </picture>
            </div>
          </div>

          <div className="article-content customer-centric-approach">
            <h5 className="typo-feature-card-modal-copy feature-card-modal-copy-highlight">
              Customer-Centric Approach.
            </h5>
            <p className="feature-card-modal-copy typo-feature-card-modal-copy">
              Tailor services to meet the specific needs and expectations of our
              clients.
            </p>
            <div className="image-container">
              <picture className="overview-consider-modals-environment-modal-zero responsive-image loaded">
                <source
                  srcSet={`${CustomerImgSmall} 1x, ${CustomerImgSmall2x} 2x`}
                  media="(max-width:734px)"
                />
                <source
                  srcSet={`${CustomerImgMedium} 1x, ${CustomerImgMedium2x} 2x`}
                  media="(max-width:1068px)"
                />
                <source
                  srcSet={`${CustomerImgLarge} 1x, ${CustomerImgLarge2x} 2x`}
                  media="(min-width:0px)"
                />
                <img
                  src={CustomerImgLarge}
                  onLoad={handleImageLoad}
                  alt="Customer-Centric Approach"
                />
              </picture>
            </div>
          </div>

          <div className="article-content technological-advancement">
            <h5 className="typo-feature-card-modal-copy feature-card-modal-copy-highlight">
              Technological Advancement.
            </h5>
            <p className="feature-card-modal-copy typo-feature-card-modal-copy">
              Utilize the latest technologies to enhance our protection systems.
            </p>
            <div className="image-container">
              <picture className="overview-consider-modals-environment-modal-zero responsive-image loaded">
                <source
                  srcSet={`${TechnologicalImgSmall} 1x, ${TechnologicalImgSmall2x} 2x`}
                  media="(max-width:734px)"
                />
                <source
                  srcSet={`${TechnologicalImgMedium} 1x, ${TechnologicalImgMedium2x} 2x`}
                  media="(max-width:1068px)"
                />
                <source
                  srcSet={`${TechnologicalImgLarge} 1x, ${TechnologicalImgLarge2x} 2x`}
                  media="(min-width:0px)"
                />
                <img
                  src={TechnologicalImgLarge}
                  onLoad={handleImageLoad}
                  alt="Technological Advancement"
                />
              </picture>
            </div>
          </div>

          <div className="article-content education-awareness">
            <h5 className="typo-feature-card-modal-copy feature-card-modal-copy-highlight">
              Education and Awareness.
            </h5>
            <p className="feature-card-modal-copy typo-feature-card-modal-copy">
              Promote understanding of lightning protection benefits and
              practices.
            </p>
            <div className="image-container">
              <picture className="overview-consider-modals-environment-modal-zero responsive-image loaded">
                <source
                  srcSet={`${EducationImgSmall} 1x, ${EducationImgSmall2x} 2x`}
                  media="(max-width:734px)"
                />
                <source
                  srcSet={`${EducationImgMedium} 1x, ${EducationImgMedium2x} 2x`}
                  media="(max-width:1068px)"
                />
                <source
                  srcSet={`${EducationImgLarge} 1x, ${EducationImgLarge2x} 2x`}
                  media="(min-width:0px)"
                />
                <img
                  src={EducationImgLarge}
                  onLoad={handleImageLoad}
                  alt="Education and Awareness"
                />
              </picture>
            </div>
          </div>

          <div className="article-content sustainable-practices ">
            <h5 className="typo-feature-card-modal-copy feature-card-modal-copy-highlight">
              Sustainable Practices.
            </h5>
            <p className="feature-card-modal-copy typo-feature-card-modal-copy">
              Employ eco-friendly methods and materials in our solutions.
            </p>
            <div className="image-container">
              <picture className="overview-consider-modals-environment-modal-zero responsive-image loaded">
                <source
                  srcSet={`${SustainableImgSmall} 1x, ${SustainableImgSmall2x} 2x`}
                  media="(max-width:734px)"
                />
                <source
                  srcSet={`${SustainableImgMedium} 1x, ${SustainableImgMedium2x} 2x`}
                  media="(max-width:1068px)"
                />
                <source
                  srcSet={`${SustainableImgLarge} 1x, ${SustainableImgLarge2x} 2x`}
                  media="(min-width:0px)"
                />
                <img
                  src={SustainableImgLarge}
                  onLoad={handleImageLoad}
                  alt="Sustainable Practices"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeGoalsObjectivesLightning;
