import React, { useState } from 'react';
import HighQualityInstallationImgSmall from './images/goals-objectives/network_high_quality_installation-small.jpg';
import HighQualityInstallationImgSmall2x from './images/goals-objectives/network_high_quality_installation-small_2x.jpg';
import HighQualityInstallationImgMedium from './images/goals-objectives/network_high_quality_installation-medium.jpg';
import HighQualityInstallationImgMedium2x from './images/goals-objectives/network_high_quality_installation-medium_2x.jpg';
import HighQualityInstallationImgLarge from './images/goals-objectives/network_high_quality_installation-large.jpg';
import HighQualityInstallationImgLarge2x from './images/goals-objectives/network_high_quality_installation-large_2x.jpg';

import CustomerFocusedImgSmall from './images/goals-objectives/network_customer_focused_solutions-small.jpg';
import CustomerFocusedImgSmall2x from './images/goals-objectives/network_customer_focused_solutions-small_2x.jpg';
import CustomerFocusedImgMedium from './images/goals-objectives/network_customer_focused_solutions-medium.jpg';
import CustomerFocusedImgMedium2x from './images/goals-objectives/network_customer_focused_solutions-medium_2x.jpg';
import CustomerFocusedImgLarge from './images/goals-objectives/network_customer_focused_solutions-large.jpg';
import CustomerFocusedImgLarge2x from './images/goals-objectives/network_customer_focused_solutions-large_2x.jpg';

import TechnologicalImgSmall from './images/goals-objectives/network_technological_advancement-small.jpg';
import TechnologicalImgSmall2x from './images/goals-objectives/network_technological_advancement-small_2x.jpg';
import TechnologicalImgMedium from './images/goals-objectives/network_technological_advancement-medium.jpg';
import TechnologicalImgMedium2x from './images/goals-objectives/network_technological_advancement-medium_2x.jpg';
import TechnologicalImgLarge from './images/goals-objectives/network_technological_advancement-large.jpg';
import TechnologicalImgLarge2x from './images/goals-objectives/network_technological_advancement-large_2x.jpg';

import ProfessionalImgSmall from './images/goals-objectives/network_professional_excellence-small.jpg';
import ProfessionalImgSmall2x from './images/goals-objectives/network_professional_excellence-small_2x.jpg';
import ProfessionalImgMedium from './images/goals-objectives/network_professional_excellence-medium.jpg';
import ProfessionalImgMedium2x from './images/goals-objectives/network_professional_excellence-medium_2x.jpg';
import ProfessionalImgLarge from './images/goals-objectives/network_professional_excellence-large.jpg';
import ProfessionalImgLarge2x from './images/goals-objectives/network_professional_excellence-large_2x.jpg';

import DeliveryImgSmall from './images/goals-objectives/network_timely_delivery-small.jpg';
import DeliveryImgSmall2x from './images/goals-objectives/network_timely_delivery-small_2x.jpg';
import DeliveryImgMedium from './images/goals-objectives/network_timely_delivery-medium.jpg';
import DeliveryImgMedium2x from './images/goals-objectives/network_timely_delivery-medium_2x.jpg';
import DeliveryImgLarge from './images/goals-objectives/network_timely_delivery-large.jpg';
import DeliveryImgLarge2x from './images/goals-objectives/network_timely_delivery-large_2x.jpg';

import SupportImgSmall from './images/goals-objectives/network_ongoing_support-small.jpg';
import SupportImgSmall2x from './images/goals-objectives/network_ongoing_support-small_2x.jpg';
import SupportImgMedium from './images/goals-objectives/network_ongoing_support-medium.jpg';
import SupportImgMedium2x from './images/goals-objectives/network_ongoing_support-medium_2x.jpg';
import SupportImgLarge from './images/goals-objectives/network_ongoing_support-large.jpg';
import SupportImgLarge2x from './images/goals-objectives/network_ongoing_support-large_2x.jpg';

const HomeGoalsObjectivesNetwork = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = (event) => {
    setIsLoaded(true);
    // 其他逻辑，例如跟踪图像加载或更新状态
    console.log('Image loaded', event);
  };
  return (
    <div className="modal-content-home-goals-objectives-network">
      <div className="feature-card-modal-content">
        <h1 className="typo-modal-tile-headline modal-headline headline">
          Network Structure Cabling
        </h1>
        <div className="modal-content-article">
          <div className="article-content high-quality-installation">
            <h5 className="typo-feature-card-modal-copy feature-card-modal-copy-highlight">
              High-Quality Installation.
            </h5>
            <p className="feature-card-modal-copy typo-feature-card-modal-copy">
              Deliver top-notch installation services that guarantee performance
              and durability.
            </p>
            <div className="image-container">
              <picture className="overview-consider-modals-environment-modal-zero responsive-image loaded">
                <source
                  srcSet={`${HighQualityInstallationImgSmall} 1x, ${HighQualityInstallationImgSmall2x} 2x`}
                  media="(max-width:734px)"
                />
                <source
                  srcSet={`${HighQualityInstallationImgMedium} 1x, ${HighQualityInstallationImgMedium2x} 2x`}
                  media="(max-width:1068px)"
                />
                <source
                  srcSet={`${HighQualityInstallationImgLarge} 1x, ${HighQualityInstallationImgLarge2x} 2x`}
                  media="(min-width:0px)"
                />
                <img
                  src={HighQualityInstallationImgLarge}
                  onLoad={handleImageLoad}
                  alt="High-Quality Installation"
                />
              </picture>
            </div>
          </div>

          <div className="article-content customer-focused-solutions">
            <h5 className="typo-feature-card-modal-copy feature-card-modal-copy-highlight">
              Customer-Focused Solutions.
            </h5>
            <p className="feature-card-modal-copy typo-feature-card-modal-copy">
              Provide customized cabling solutions that align with client
              requirements.
            </p>
            <div className="image-container">
              <picture className="overview-consider-modals-environment-modal-zero responsive-image loaded">
                <source
                  srcSet={`${CustomerFocusedImgSmall} 1x, ${CustomerFocusedImgSmall2x} 2x`}
                  media="(max-width:734px)"
                />
                <source
                  srcSet={`${CustomerFocusedImgMedium} 1x, ${CustomerFocusedImgMedium2x} 2x`}
                  media="(max-width:1068px)"
                />
                <source
                  srcSet={`${CustomerFocusedImgLarge} 1x, ${CustomerFocusedImgLarge2x} 2x`}
                  media="(min-width:0px)"
                />
                <img
                  src={CustomerFocusedImgLarge}
                  onLoad={handleImageLoad}
                  alt="Customer-Focused Solutions"
                />
              </picture>
            </div>
          </div>

          <div className="article-content technological-advancement">
            <h5 className="typo-feature-card-modal-copy feature-card-modal-copy-highlight">
              Technological Advancement.
            </h5>
            <p className="feature-card-modal-copy typo-feature-card-modal-copy">
              Incorporate cutting-edge technology to support future-ready
              network infrastructures.
            </p>
            <div className="image-container">
              <picture className="overview-consider-modals-environment-modal-zero responsive-image loaded">
                <source
                  srcSet={`${TechnologicalImgSmall} 1x, ${TechnologicalImgSmall2x} 2x`}
                  media="(max-width:734px)"
                />
                <source
                  srcSet={`${TechnologicalImgMedium} 1x, ${TechnologicalImgMedium2x} 2x`}
                  media="(max-width:1068px)"
                />
                <source
                  srcSet={`${TechnologicalImgLarge} 1x, ${TechnologicalImgLarge2x} 2x`}
                  media="(min-width:0px)"
                />
                <img
                  src={TechnologicalImgLarge}
                  onLoad={handleImageLoad}
                  alt="Technological Advancement"
                />
              </picture>
            </div>
          </div>

          <div className="article-content professional-excellence">
            <h5 className="typo-feature-card-modal-copy feature-card-modal-copy-highlight">
              Professional Excellence.
            </h5>
            <p className="feature-card-modal-copy typo-feature-card-modal-copy">
              Maintain high standards of professionalism in all aspects of our
              work.
            </p>
            <div className="image-container">
              <picture className="overview-consider-modals-environment-modal-zero responsive-image loaded">
                <source
                  srcSet={`${ProfessionalImgSmall} 1x, ${ProfessionalImgSmall2x} 2x`}
                  media="(max-width:734px)"
                />
                <source
                  srcSet={`${ProfessionalImgMedium} 1x, ${ProfessionalImgMedium2x} 2x`}
                  media="(max-width:1068px)"
                />
                <source
                  srcSet={`${ProfessionalImgLarge} 1x, ${ProfessionalImgLarge2x} 2x`}
                  media="(min-width:0px)"
                />
                <img
                  src={ProfessionalImgLarge}
                  onLoad={handleImageLoad}
                  alt="Professional Excellence"
                />
              </picture>
            </div>
          </div>

          <div className="article-content timely-delivery">
            <h5 className="typo-feature-card-modal-copy feature-card-modal-copy-highlight">
              Timely Delivery.
            </h5>
            <p className="feature-card-modal-copy typo-feature-card-modal-copy">
              Ensure projects are completed on schedule without compromising
              quality.
            </p>
            <div className="image-container">
              <picture className="overview-consider-modals-environment-modal-zero responsive-image loaded">
                <source
                  srcSet={`${DeliveryImgSmall} 1x, ${DeliveryImgSmall2x} 2x`}
                  media="(max-width:734px)"
                />
                <source
                  srcSet={`${DeliveryImgMedium} 1x, ${DeliveryImgMedium2x} 2x`}
                  media="(max-width:1068px)"
                />
                <source
                  srcSet={`${DeliveryImgLarge} 1x, ${DeliveryImgLarge2x} 2x`}
                  media="(min-width:0px)"
                />
                <img
                  src={DeliveryImgLarge}
                  onLoad={handleImageLoad}
                  alt="Timely Delivery"
                />
              </picture>
            </div>
          </div>

          <div className="article-content ongoing-support">
            <h5 className="typo-feature-card-modal-copy feature-card-modal-copy-highlight">
              Ongoing Support.
            </h5>
            <p className="feature-card-modal-copy typo-feature-card-modal-copy">
              Offer continuous support and maintenance to ensure long-term
              client satisfaction.
            </p>
            <div className="image-container">
              <picture className="overview-consider-modals-environment-modal-zero responsive-image loaded">
                <source
                  srcSet={`${SupportImgSmall} 1x, ${SupportImgSmall2x} 2x`}
                  media="(max-width:734px)"
                />
                <source
                  srcSet={`${SupportImgMedium} 1x, ${SupportImgMedium2x} 2x`}
                  media="(max-width:1068px)"
                />
                <source
                  srcSet={`${SupportImgLarge} 1x, ${SupportImgLarge2x} 2x`}
                  media="(min-width:0px)"
                />
                <img
                  src={SupportImgLarge}
                  onLoad={handleImageLoad}
                  alt="Ongoing Support"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeGoalsObjectivesNetwork;
