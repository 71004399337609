import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import SectorAviation from './SectorAviation';
import SectorPrivate from './SectorPrivate';
import SectorProperty from './SectorProperty';
import SectorRailway from './SectorRailway';
import SectorEducation from './SectorEducation';
import SectorGoverment from './SectorGoverment';
import LpsHealthCheck from './LpsHealthCheck';
import NetworkCard from './NetworkCard';
import './project.css';
import WorkHeroSmall from './images/hero-banner-work-small_2x.jpg';
import WorkHeroLarge from './images/hero-banner-work-large_2x.jpg';

import aviationSectorS from './images/earthing-lightning/work_aviation_sector-small.jpg';
import aviationSectorS2x from './images/earthing-lightning/work_aviation_sector-small_2x.jpg';
import aviationSectorM from './images/earthing-lightning/work_aviation_sector-medium.jpg';
import aviationSectorM2x from './images/earthing-lightning/work_aviation_sector-medium_2x.jpg';
import aviationSectorL from './images/earthing-lightning/work_aviation_sector-large.jpg';
import aviationSectorL2x from './images/earthing-lightning/work_aviation_sector-large_2x.jpg';
import aviationSectorXL from './images/earthing-lightning/work_aviation_sector-xlarge.jpg';
import aviationSectorXL2x from './images/earthing-lightning/work_aviation_sector-xlarge_2x.jpg';

import privateSectorS from './images/earthing-lightning/work_private_sector-small.jpg';
import privateSectorS2x from './images/earthing-lightning/work_private_sector-small_2x.jpg';
import privateSectorM from './images/earthing-lightning/work_private_sector-medium.jpg';
import privateSectorM2x from './images/earthing-lightning/work_private_sector-medium_2x.jpg';
import privateSectorL from './images/earthing-lightning/work_private_sector-large.jpg';
import privateSectorL2x from './images/earthing-lightning/work_private_sector-large_2x.jpg';
import privateSectorXL from './images/earthing-lightning/work_private_sector-xlarge.jpg';
import privateSectorXL2x from './images/earthing-lightning/work_private_sector-xlarge_2x.jpg';

import educationSectorS from './images/earthing-lightning/work_education_sector-small.jpg';
import educationSectorS2x from './images/earthing-lightning/work_education_sector-small_2x.jpg';
import educationSectorM from './images/earthing-lightning/work_education_sector-medium.jpg';
import educationSectorM2x from './images/earthing-lightning/work_education_sector-medium_2x.jpg';
import educationSectorL from './images/earthing-lightning/work_education_sector-large.jpg';
import educationSectorL2x from './images/earthing-lightning/work_education_sector-large_2x.jpg';
import educationSectorXL from './images/earthing-lightning/work_education_sector-xlarge.jpg';
import educationSectorXL2x from './images/earthing-lightning/work_education_sector-xlarge_2x.jpg';

import transportSectorS from './images/earthing-lightning/work_transport_sector-small.jpg';
import transportSectorS2x from './images/earthing-lightning/work_transport_sector-small_2x.jpg';
import transportSectorM from './images/earthing-lightning/work_transport_sector-medium.jpg';
import transportSectorM2x from './images/earthing-lightning/work_transport_sector-medium_2x.jpg';
import transportSectorL from './images/earthing-lightning/work_transport_sector-large.jpg';
import transportSectorL2x from './images/earthing-lightning/work_transport_sector-large_2x.jpg';
import transportSectorXL from './images/earthing-lightning/work_transport_sector-xlarge.jpg';
import transportSectorXL2x from './images/earthing-lightning/work_transport_sector-xlarge_2x.jpg';

import governmentSectorS from './images/earthing-lightning/work_government_sector-small.jpg';
import governmentSectorS2x from './images/earthing-lightning/work_government_sector-small_2x.jpg';
import governmentSectorM from './images/earthing-lightning/work_government_sector-medium.jpg';
import governmentSectorM2x from './images/earthing-lightning/work_government_sector-medium_2x.jpg';
import governmentSectorL from './images/earthing-lightning/work_government_sector-large.jpg';
import governmentSectorL2x from './images/earthing-lightning/work_government_sector-large_2x.jpg';
import governmentSectorXL from './images/earthing-lightning/work_government_sector-xlarge.jpg';
import governmentSectorXL2x from './images/earthing-lightning/work_government_sector-xlarge_2x.jpg';

import residentialSectorS from './images/earthing-lightning/work_residential_sector-small.jpg';
import residentialSectorS2x from './images/earthing-lightning/work_residential_sector-small_2x.jpg';
import residentialSectorM from './images/earthing-lightning/work_residential_sector-medium.jpg';
import residentialSectorM2x from './images/earthing-lightning/work_residential_sector-medium_2x.jpg';
import residentialSectorL from './images/earthing-lightning/work_residential_sector-large.jpg';
import residentialSectorL2x from './images/earthing-lightning/work_residential_sector-large_2x.jpg';
import residentialSectorXL from './images/earthing-lightning/work_residential_sector-xlarge.jpg';
import residentialSectorXL2x from './images/earthing-lightning/work_residential_sector-xlarge_2x.jpg';

import workNetworkCommercialSmall from './images/network-structured/network_commercial_trx_small.jpg';
import workNetworkCommercialSmall2x from './images/network-structured/network_commercial_trx_small_2x.jpg';
import workNetworkCommercialMedium from './images/network-structured/network_commercial_trx_medium.jpg';
import workNetworkCommercialMedium2x from './images/network-structured/network_commercial_trx_medium_2x.jpg';
import workNetworkCommercialLarge from './images/network-structured/network_commercial_trx_large.jpg';
import workNetworkCommercialLarge2x from './images/network-structured/network_commercial_trx_large_2x.jpg';
import workNetworkCommercialXlarge from './images/network-structured/network_commercial_trx_xlarge.jpg';
import workNetworkCommercialXlarge2x from './images/network-structured/network_commercial_trx_xlarge_2x.jpg';

import workMecuryDataCenterSmall from './images/network-structured/network_commercial_mercury_small.jpg';
import workMecuryDataCenterSmall2x from './images/network-structured/network_commercial_mercury_small_2x.jpg';
import workMecuryDataCenterMedium from './images/network-structured/network_commercial_mercury_medium.jpg';
import workMecuryDataCenterMedium2x from './images/network-structured/network_commercial_mercury_medium_2x.jpg';
import workMecuryDataCenterLarge from './images/network-structured/network_commercial_mercury_large.jpg';
import workMecuryDataCenterLarge2x from './images/network-structured/network_commercial_mercury_large_2x.jpg';
import workMecuryDataCenterXlarge from './images/network-structured/network_commercial_mercury_xlarge.jpg';
import workMecuryDataCenterXlarge2x from './images/network-structured/network_commercial_mercury_xlarge_2x.jpg';

import workNetworkRailwaySmall from './images/network-structured/network_railway_rts_small.jpg';
import workNetworkRailwaySmall2x from './images/network-structured/network_railway_rts_small_2x.jpg';
import workNetworkRailwayMedium from './images/network-structured/network_railway_rts_medium.jpg';
import workNetworkRailwayMedium2x from './images/network-structured/network_railway_rts_medium_2x.jpg';
import workNetworkRailwayLarge from './images/network-structured/network_railway_rts_large.jpg';
import workNetworkRailwayLarge2x from './images/network-structured/network_railway_rts_large_2x.jpg';
import workNetworkRailwayXlarge from './images/network-structured/network_railway_rts_xlarge.jpg';
import workNetworkRailwayXlarge2x from './images/network-structured/network_railway_rts_xlarge_2x.jpg';

import workNetworkGovermentSmall from './images/network-structured/network_goverment_klia_small.jpg';
import workNetworkGovermentSmall2x from './images/network-structured/network_goverment_klia_small_2x.jpg';
import workNetworkGovermentMedium from './images/network-structured/network_goverment_klia_medium.jpg';
import workNetworkGovermentMedium2x from './images/network-structured/network_goverment_klia_medium_2x.jpg';
import workNetworkGovermentLarge from './images/network-structured/network_goverment_klia_large.jpg';
import workNetworkGovermentLarge2x from './images/network-structured/network_goverment_klia_large_2x.jpg';
import workNetworkGovermentXlarge from './images/network-structured/network_goverment_klia_xlarge.jpg';
import workNetworkGovermentXlarge2x from './images/network-structured/network_goverment_klia_xlarge_2x.jpg';

import lpsBgImageSmall from './images/lps-check/lps_health_check_small.jpg';
import lpsBgImageSmall2x from './images/lps-check/lps_health_check_small_2x.jpg';
import lpsBgImageMedium from './images/lps-check/lps_health_check_medium.jpg';
import lpsBgImageMedium2x from './images/lps-check/lps_health_check_medium_2x.jpg';
import lpsBgImageLarge from './images/lps-check/lps_health_check_large.jpg';
import lpsBgImageLarge2x from './images/lps-check/lps_health_check_large_2x.jpg';
import lpsBgImageXlarge from './images/lps-check/lps_health_check_xlarge.jpg';
import lpsBgImageXlarge2x from './images/lps-check/lps_health_check_xlarge_2x.jpg';

const sectors = [
  {
    title: 'Aviation Sector',
    label: 'Airport',
    imageSrcS: aviationSectorS,
    imageSrcS2x: aviationSectorS2x,
    imageSrcM: aviationSectorM,
    imageSrcM2x: aviationSectorM2x,
    imageSrcL: aviationSectorL,
    imageSrcL2x: aviationSectorL2x,
    imageSrcXL: aviationSectorXL,
    imageSrcXL2x: aviationSectorXL2x,
    component: <SectorAviation />,
  },
  {
    title: 'Private Sector',
    label: 'Commercial & Office',
    imageSrcS: privateSectorS,
    imageSrcS2x: privateSectorS2x,
    imageSrcM: privateSectorM,
    imageSrcM2x: privateSectorM2x,
    imageSrcL: privateSectorL,
    imageSrcL2x: privateSectorL2x,
    imageSrcXL: privateSectorXL,
    imageSrcXL2x: privateSectorXL2x,
    component: <SectorPrivate />,
  },
  {
    title: 'Education Sector',
    label: 'School',
    imageSrcS: educationSectorS,
    imageSrcS2x: educationSectorS2x,
    imageSrcM: educationSectorM,
    imageSrcM2x: educationSectorM2x,
    imageSrcL: educationSectorL,
    imageSrcL2x: educationSectorL2x,
    imageSrcXL: educationSectorXL,
    imageSrcXL2x: educationSectorXL2x,
    component: <SectorEducation />,
  },
  {
    title: 'Infrastructure Industries',
    label: 'Transport',
    imageSrcS: transportSectorS,
    imageSrcS2x: transportSectorS2x,
    imageSrcM: transportSectorM,
    imageSrcM2x: transportSectorM2x,
    imageSrcL: transportSectorL,
    imageSrcL2x: transportSectorL2x,
    imageSrcXL: transportSectorXL,
    imageSrcXL2x: transportSectorXL2x,
    component: <SectorRailway />,
  },
  {
    title: 'Government Sector',
    label: 'Building',
    imageSrcS: governmentSectorS,
    imageSrcS2x: governmentSectorS2x,
    imageSrcM: governmentSectorM,
    imageSrcM2x: governmentSectorM2x,
    imageSrcL: governmentSectorL,
    imageSrcL2x: governmentSectorL2x,
    imageSrcXL: governmentSectorXL,
    imageSrcXL2x: governmentSectorXL2x,
    component: <SectorGoverment />,
  },
  {
    title: 'Real Estate',
    label: 'Residential',
    imageSrcS: residentialSectorS,
    imageSrcS2x: residentialSectorS2x,
    imageSrcM: residentialSectorM,
    imageSrcM2x: residentialSectorM2x,
    imageSrcL: residentialSectorL,
    imageSrcL2x: residentialSectorL2x,
    imageSrcXL: residentialSectorXL,
    imageSrcXL2x: residentialSectorXL2x,
    component: <SectorProperty />,
  },
];

export default function OurWork() {
  const [LpsModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [activeComponent, setActiveComponent] = useState(null);

  const handleShowComponent = (component) => {
    setActiveComponent(component);
  };

  const handleClose = () => setActiveComponent(null);

  // Refs for scroll containers and buttons
  const containerRef = useRef(null);
  const containerRefNetwork = useRef(null);
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);
  const prevButtonRefNetwork = useRef(null);
  const nextButtonRefNetwork = useRef(null);
  const scrollDistance = 300; // Adjust scroll distance as needed

  // Single useEffect to manage both scroll containers
  useEffect(() => {
    const containers = [
      {
        container: containerRef.current,
        prevButton: prevButtonRef.current,
        nextButton: nextButtonRef.current,
      },
      {
        container: containerRefNetwork.current,
        prevButton: prevButtonRefNetwork.current,
        nextButton: nextButtonRefNetwork.current,
      },
    ];

    const updateButtonStates = (container, prevButton, nextButton) => {
      const atStart = container.scrollLeft === 0;
      const atEnd =
        container.scrollLeft + container.clientWidth >= container.scrollWidth;
      prevButton.disabled = atStart;
      nextButton.disabled = atEnd;
    };

    const handlePrevClick = (container) => {
      container.scrollBy({ left: -scrollDistance, behavior: 'smooth' });
    };

    const handleNextClick = (container) => {
      container.scrollBy({ left: scrollDistance, behavior: 'smooth' });
    };

    containers.forEach(({ container, prevButton, nextButton }) => {
      if (container && prevButton && nextButton) {
        updateButtonStates(container, prevButton, nextButton);

        // Attach event listeners for buttons
        prevButton.addEventListener('click', () => handlePrevClick(container));
        nextButton.addEventListener('click', () => handleNextClick(container));

        // Attach scroll listener to update button states
        container.addEventListener('scroll', () =>
          updateButtonStates(container, prevButton, nextButton),
        );
      }
    });

    // Cleanup event listeners on component unmount
    return () => {
      containers.forEach(({ container, prevButton, nextButton }) => {
        if (container && prevButton && nextButton) {
          prevButton.removeEventListener('click', () =>
            handlePrevClick(container),
          );
          nextButton.removeEventListener('click', () =>
            handleNextClick(container),
          );
          container.removeEventListener('scroll', () =>
            updateButtonStates(container, prevButton, nextButton),
          );
        }
      });
    };
  }, []);
  return (
    <>
      <section className="as-container-column as-columns--1up as-banner as-banner--top">
        <div className="column-item">
          <div className="as-banner-cont">
            <div className="as-banner-image as-banner-image--top">
              <img
                sizes="(min-width:735px) 735w, 100vw"
                src={WorkHeroLarge}
                alt="Zem Engineering Projects"
                srcSet={`${WorkHeroSmall} 735w, ${WorkHeroLarge} 1440w`}
              />
            </div>
          </div>
        </div>{' '}
      </section>
      <section className="section section-consider no-pad-bottom staggered-end ">
        <div className="section-content-responsive">
          <div className="section-header row">
            <h2 className="section-header-headline typo-section-headline">
              Earthing & Lightning Protection
            </h2>
            <p class="typo-section-copy section-intro-copy">
              ZEM has extensive experience in providing earthing and lightning
              protection systems across various sectors, ensuring safety and
              compliance with industry standards.
            </p>
          </div>
        </div>
        <div
          id="scroll-gallery-feature-cards"
          data-scroll-gallery-feature-cards="earthing-lightning-protection"
          data-scroll-gallery="earthing-lightning-protection"
          data-analytics-gallery-id="earthing-lightning-protection"
          aria-label="Earthing & Lightning Protection"
          className="gallery gallery-align-start gallery-feature-cards"
          data-component-list="ScrollGallery"
        >
          <div
            id="scrollContainer1"
            className="scroll-container"
            ref={containerRef}
          >
            <div className="item-container">
              <ul className="card-set">
                {sectors.map((sector, index) => (
                  <li key={index} className="gallery-item">
                    <div className="feature-card card-container">
                      <div className="project-card">
                        <div className="card-modifier card-padding theme-dark fixed-width">
                          <div className="card-viewport-content">
                            <div className="feature-card-content">
                              <div className="feature-card-copy">
                                <p className="typo-feature-card-label feature-card-label">
                                  {sector.label}
                                </p>
                                <h3 className="typo-card-headline feature-card-headline">
                                  {sector.title}
                                </h3>
                                <button
                                  className="banner-card-button button button-base button-learn button-always-dark"
                                  role="button"
                                >
                                  <span className="icon-copy">View All</span>
                                </button>
                              </div>
                              <figure className="feature-card-image-container image-bottom-center">
                                <source
                                  srcSet="{sector.imageSrcS} 1x, {sector.imageSrcS2x} 2x}"
                                  media="(max-width:734px)"
                                  alt={sector.title}
                                  className="feature-card-image"
                                />
                                <source
                                  srcSet="{sector.imageSrcM} 1x, {sector.imageSrcM2x} 2x}"
                                  media="(max-width:1068px)"
                                  alt={sector.title}
                                  className="feature-card-image"
                                />
                                <source
                                  srcSet="{sector.imageSrcX} 1x, {sector.imageSrcX2x} 2x}"
                                  media="(max-width:1440px)"
                                  alt={sector.title}
                                  className="feature-card-image"
                                />
                                <source
                                  srcSet="{sector.imageSrcXL} 1x, {sector.imageSrcXL2x} 2x}"
                                  media="(min-width:0px)"
                                  alt={sector.title}
                                  className="feature-card-image"
                                />
                                <img
                                  src={sector.imageSrcXL}
                                  alt={sector.title}
                                  className="feature-card-image"
                                />
                              </figure>
                            </div>
                          </div>
                        </div>
                        <button
                          onClick={() => handleShowComponent(sector.component)}
                          data-modal-open=""
                          data-analytics-intrapage-link=""
                          className="card-modal-trigger modal-trigger card-cta-modal-button"
                          data-analytics-click="prop3:open modal - chip and battery life"
                          aria-label={sector.title}
                        >
                          <div className="modal-trigger-container">
                            <span className="card-cta-modal-button-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                className="card-cta-modal-button-small-icon card-modal-button-small-icon"
                              >
                                <path d="M17.25,8.51H11.5V2.75A1.5,1.5,0,0,0,10,1.25h0a1.5,1.5,0,0,0-1.5,1.5V8.5H2.75a1.5,1.5,0,0,0,0,3H8.5v5.75a1.5,1.5,0,0,0,1.5,1.5h0a1.5,1.5,0,0,0,1.5-1.5V11.5h5.75a1.5,1.5,0,0,0,0-3Z" />
                              </svg>
                            </span>
                          </div>
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div
            data-analytics-gallery-interaction-type="paddlenav"
            data-gallery-paddlenav=""
            class="paddlenav paddlenav-alpha"
          >
            <ul className="sticky-element">
              <li className="left-item">
                <button
                  id="prevButton1"
                  aria-label="Previous"
                  class="paddlenav-arrow paddlenav-arrow-previous"
                  disabled="true"
                  ref={prevButtonRef}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
                    <path d="M21.559,12.062 L15.618,17.984 L21.5221,23.944 C22.105,24.533 22.1021,25.482 21.5131,26.065 C21.2211,26.355 20.8391,26.4999987 20.4571,26.4999987 C20.0711,26.4999987 19.6851,26.352 19.3921,26.056 L12.4351,19.034 C11.8531,18.446 11.8551,17.4999987 12.4411,16.916 L19.4411,9.938 C20.0261,9.353 20.9781,9.354 21.5621,9.941 C22.1471,10.528 22.1451,11.478 21.5591,12.062 L21.559,12.062 Z"></path>
                  </svg>
                </button>
              </li>
              <li className="right-item">
                <button
                  id="nextButton1"
                  aria-label="Next"
                  class="paddlenav-arrow paddlenav-arrow-next"
                  ref={nextButtonRef}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
                    <path d="M23.5587,16.916 C24.1447,17.4999987 24.1467,18.446 23.5647,19.034 L16.6077,26.056 C16.3147,26.352 15.9287,26.4999987 15.5427,26.4999987 C15.1607,26.4999987 14.7787,26.355 14.4867,26.065 C13.8977,25.482 13.8947,24.533 14.4777,23.944 L20.3818,17.984 L14.4408,12.062 C13.8548,11.478 13.8528,10.5279 14.4378,9.941 C15.0218,9.354 15.9738,9.353 16.5588,9.938 L23.5588,16.916 L23.5587,16.916 Z"></path>
                  </svg>
                </button>
              </li>
              <div className="scrim"></div>
            </ul>
          </div>
        </div>
        {activeComponent && (
          <Modal
            show={true}
            onHide={handleClose}
            className="modal-page-overlay feature-card-modal"
          >
            <Modal.Header closeButton></Modal.Header>
            <div className="modal-content-container">
              <Modal.Body className="modal-content-wrapper">
                <div className="feature-card-modal-content">
                  {activeComponent}
                </div>
              </Modal.Body>
            </div>
          </Modal>
        )}
      </section>

      <section
        className="section section-banner background-alt no-pad-bottom staggered-end"
        data-anim-scroll-group="Banner"
        data-component-list="StaggeredFadeIn"
        data-analytics-section-engagement="name:latest models"
      >
        <div className="section-content-responsive">
          <div className="section-header row">
            <h2
              className="section-header-headline typo-section-headline"
              data-staggered-anchor=""
              data-staggered-before-item=""
            >
              LPS Health Check
            </h2>
            <p class="typo-section-copy section-intro-copy">
              ZEM provides comprehensive Lightning Protection System (LPS)
              Health Check services to ensure the safety and compliance of
              structures in various sectors.
            </p>
          </div>

          <div className="banner-card banner-card-banner banner-card-1-up banner-card-image-type-background banner-card-text-column-placement-start banner-card-text-row-placement-top banner-card-text-alignment-center has-ctas">
            <a
              onClick={openModal}
              className="banner-card-active-area"
              data-films-modal-link="lps-health-check"
              aria-hidden="true"
              tabIndex="-1"
            ></a>
            <div className="banner-card-row row">
              <div className="column banner-card-copy-column">
                <h2 className="banner-card-headline">
                  Comprehensive Lightning Protection System (LPS) Health Check
                  Services for Safety and Compliance
                </h2>

                <div className="banner-card-ctas banner-card-ctas-inline banner-card-ctas-primary-neutral">
                  <button
                    onClick={openModal}
                    id="lps-health-check"
                    className="banner-card-button button button-base button-learn"
                    data-films-modal-link=""
                    role="button"
                    aria-label="LPS Health Check"
                  >
                    <span className="icon-copy">View All</span>
                  </button>
                </div>
              </div>
              <div className="column banner-card-image-column">
                <picture
                  id="overview-lps-health-check"
                  className="overview-banner-guided-tour banner-card-image loaded"
                  data-lazy=""
                  data-picture-loaded=""
                >
                  <source
                    srcSet={`${lpsBgImageSmall} 1x, ${lpsBgImageSmall2x} 2x`}
                    media="(max-width:734px)"
                  />
                  <source
                    srcSet={`${lpsBgImageMedium} 1x, ${lpsBgImageMedium2x} 2x`}
                    media="(max-width:1068px)"
                  />
                  <source
                    srcSet={`${lpsBgImageLarge} 1x, ${lpsBgImageLarge2x} 2x`}
                    media="(max-width:1440px)"
                  />
                  <source
                    srcSet={`${lpsBgImageXlarge} 1x, ${lpsBgImageXlarge2x} 2x`}
                    media="(min-width:0px)"
                  />
                  <img src={lpsBgImageXlarge} alt="" />
                </picture>
                <noscript>
                  <picture className="overview-banner-guided-tour banner-card-image">
                    <source
                      srcSet={`${lpsBgImageSmall} 1x, ${lpsBgImageSmall2x} 2x`}
                      media="(max-width:734px)"
                    />
                    <source
                      srcSet={`${lpsBgImageMedium} 1x, ${lpsBgImageMedium2x} 2x`}
                      media="(max-width:1068px)"
                    />
                    <source
                      srcSet={`${lpsBgImageLarge} 1x, ${lpsBgImageLarge2x} 2x`}
                      media="(max-width:1440px)"
                    />
                    <source
                      srcSet={`${lpsBgImageXlarge} 1x, ${lpsBgImageXlarge2x} 2x`}
                      media="(min-width:0px)"
                    />
                    <img src={lpsBgImageXlarge} alt="" />
                  </picture>
                </noscript>
              </div>
            </div>
          </div>
          {LpsModalOpen && (
            <Modal
              show={LpsModalOpen}
              onHide={closeModal} // 关闭时调用 closeModal
              className="modal-page-overlay feature-card-modal"
            >
              <Modal.Header closeButton></Modal.Header>
              <div className="modal-content-container">
                <Modal.Body className="modal-content-wrapper">
                  <div className="feature-card-modal-content">
                    <LpsHealthCheck />
                  </div>
                </Modal.Body>
              </div>
            </Modal>
          )}
        </div>
      </section>

      <section
        className="section section-work-network staggered-end"
        data-anim-scroll-group="Essentials"
        data-component-list="StaggeredFadeIn"
        data-analytics-section-engagement="name:iphone essentials"
      >
        <div className="section-content-responsive">
          <div className="section-header row">
            <h2
              className="section-header-headline typo-section-headline"
              data-staggered-anchor=""
              data-staggered-before-item=""
            >
              Network Structured Cablings
            </h2>
            <p class="typo-section-copy section-intro-copy">
              ZEM also specializes in the installation and maintenance of
              structured cabling for network infrastructure across different
              industries.
            </p>
          </div>
        </div>
        <div
          id="scroll-gallery-feature-cards"
          data-scroll-gallery-feature-cards="scroll-gallery-feature-cards"
          data-scroll-gallery="scroll-gallery-feature-cards"
          data-analytics-gallery-id="Network-Structured-Cablings"
          aria-label="Network Structured Cablings"
          className="gallery gallery-align-start gallery-feature-cards"
          data-component-list="ScrollGallery"
          style={{ '--scroll-bar-width': '0px' }}
        >
          <div
            id="scrollContainer2"
            className="scroll-container"
            ref={containerRefNetwork}
          >
            <div className="item-container">
              <ul className="card-set" role="list">
                {[
                  {
                    id: 'feature-card-1',
                    label: 'Commercial & Office',
                    headline: 'Tun Razak Exchange',
                    imgSrc: workNetworkCommercialXlarge,
                    imgSrcSet: {
                      small: `${workNetworkCommercialSmall} 1x, ${workNetworkCommercialSmall2x} 2x`,
                      medium: `${workNetworkCommercialMedium} 1x, ${workNetworkCommercialMedium2x} 2x`,
                      large: `${workNetworkCommercialLarge} 1x, ${workNetworkCommercialLarge2x} 2x`,
                      xlarge: `${workNetworkCommercialXlarge} 1x, ${workNetworkCommercialXlarge2x} 2x`,
                    },
                    modalTarget: '#trxchange',
                  },
                  {
                    id: 'feature-card-2',
                    label: 'Commercial & Office',
                    headline: 'Mercury Data Center',
                    subheadline: '(2024 On-going)',
                    imgSrc: workMecuryDataCenterXlarge,
                    imgSrcSet: {
                      small: `${workMecuryDataCenterSmall} 1x, ${workMecuryDataCenterSmall2x} 2x`,
                      medium: `${workMecuryDataCenterMedium} 1x, ${workMecuryDataCenterMedium2x} 2x`,
                      large: `${workMecuryDataCenterLarge} 1x, ${workMecuryDataCenterLarge2x} 2x`,
                      xlarge: `${workMecuryDataCenterXlarge} 1x, ${workMecuryDataCenterXlarge2x} 2x`,
                    },
                    modalTarget: '#railwayModal',
                  },
                  {
                    id: 'feature-card-3',
                    label: 'Railway Industries',
                    headline: 'RTS Link',
                    subheadline: '(2024 On-going)',
                    imgSrc: workNetworkRailwayXlarge,
                    imgSrcSet: {
                      small: `${workNetworkRailwaySmall} 1x, ${workNetworkRailwaySmall2x} 2x`,
                      medium: `${workNetworkRailwayMedium} 1x, ${workNetworkRailwayMedium2x} 2x`,
                      large: `${workNetworkRailwayLarge} 1x, ${workNetworkRailwayLarge2x} 2x`,
                      xlarge: `${workNetworkRailwayXlarge} 1x, ${workNetworkRailwayXlarge2x} 2x`,
                    },
                    modalTarget: '#railwayModal',
                  },
                  {
                    id: 'feature-card-4',
                    label: 'Government Sector',
                    headline: 'KLIA',
                    imgSrc: workNetworkGovermentXlarge,
                    imgSrcSet: {
                      small: `${workNetworkGovermentSmall} 1x, ${workNetworkGovermentSmall2x} 2x`,
                      medium: `${workNetworkGovermentMedium} 1x, ${workNetworkGovermentMedium2x} 2x`,
                      large: `${workNetworkGovermentLarge} 1x, ${workNetworkGovermentLarge2x} 2x`,
                      xlarge: `${workNetworkGovermentXlarge} 1x, ${workNetworkGovermentXlarge2x} 2x`,
                    },
                    modalTarget: '#kliaModal',
                  },
                ].map((card, index) => (
                  <li
                    role="listitem"
                    className={`gallery-item grid-item ${
                      index === 0 ? 'current' : ''
                    }`}
                    data-ac-gallery-item=""
                    data-staggered-item=""
                    key={card.id}
                  >
                    <div id={card.id} className="feature-card card-container">
                      <NetworkCard card={card} /> {/* 传递 card 数据 */}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div
            data-analytics-gallery-interaction-type="paddlenav"
            data-gallery-paddlenav=""
            class="paddlenav paddlenav-alpha"
          >
            <ul className="sticky-element">
              <li className="left-item">
                <button
                  id="prevButton1"
                  aria-label="Previous"
                  class="paddlenav-arrow paddlenav-arrow-previous"
                  disabled="true"
                  ref={prevButtonRefNetwork}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
                    <path d="M21.559,12.062 L15.618,17.984 L21.5221,23.944 C22.105,24.533 22.1021,25.482 21.5131,26.065 C21.2211,26.355 20.8391,26.4999987 20.4571,26.4999987 C20.0711,26.4999987 19.6851,26.352 19.3921,26.056 L12.4351,19.034 C11.8531,18.446 11.8551,17.4999987 12.4411,16.916 L19.4411,9.938 C20.0261,9.353 20.9781,9.354 21.5621,9.941 C22.1471,10.528 22.1451,11.478 21.5591,12.062 L21.559,12.062 Z"></path>
                  </svg>
                </button>
              </li>
              <li className="right-item">
                <button
                  id="nextButton1"
                  aria-label="Next"
                  class="paddlenav-arrow paddlenav-arrow-next"
                  ref={nextButtonRefNetwork}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
                    <path d="M23.5587,16.916 C24.1447,17.4999987 24.1467,18.446 23.5647,19.034 L16.6077,26.056 C16.3147,26.352 15.9287,26.4999987 15.5427,26.4999987 C15.1607,26.4999987 14.7787,26.355 14.4867,26.065 C13.8977,25.482 13.8947,24.533 14.4777,23.944 L20.3818,17.984 L14.4408,12.062 C13.8548,11.478 13.8528,10.5279 14.4378,9.941 C15.0218,9.354 15.9738,9.353 16.5588,9.938 L23.5588,16.916 L23.5587,16.916 Z"></path>
                  </svg>
                </button>
              </li>
              <div className="scrim"></div>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}
