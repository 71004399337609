import React, { useState, useEffect, useRef } from 'react';
import LogoAteg from './images/partner/partner-ateg.jpg';
import LogoCitel from './images/partner/partner-citel.jpg';
import LogoCommscope from './images/partner/partner-commscope.jpg';
import LogoPrysmain from './images/partner/partner-prysmian.jpg';
import LogoDraka from './images/partner/partner-draka.jpg';
import LogoFluke from './images/partner/partner-fluke.jpg';
import LogoFujikura from './images/partner/partner-fujikura.jpg';
import LogoFurse from './images/partner/partner-furse.jpg';
import LogoKvc from './images/partner/partner-kvc.jpg';
import LogoKyoritsu from './images/partner/partner-kyoritsu.jpg';
import LogoLightningtech from './images/partner/partner-lightningtech.jpg';
import LogoNovaris from './images/partner/partner-novaris.jpg';
import LogoPekat from './images/partner/partner-pekat.jpg';
import LogoSanwa from './images/partner/partner-sanwa.jpg';
import LogoSiemon from './images/partner/partner-siemon.jpg';
import LogoUnitech from './images/partner/partner-unitech.jpg';

// Example partner data
const partners = [
  {
    imageCatagoryName: 'Earthing & Lightning Protection',
    imageSrc: LogoPekat,
    altText: 'Pekat',
  },
  {
    imageCatagoryName: 'Earthing & Lightning Protection',
    imageSrc: LogoFurse,
    altText: 'Furse',
  },
  {
    imageCatagoryName: 'Earthing & Lightning Protection',
    imageSrc: LogoKvc,
    altText: 'KVC',
  },
  {
    imageCatagoryName: 'Earthing & Lightning Protection',
    imageSrc: LogoAteg,
    altText: 'Ateg',
  },
  {
    imageCatagoryName: 'Earthing & Lightning Protection',
    imageSrc: LogoLightningtech,
    altText: 'Lightningtech',
  },
  {
    imageCatagoryName: 'Earthing & Lightning Protection',
    imageSrc: LogoUnitech,
    altText: 'Unitech',
  },
  {
    imageCatagoryName: 'Lightning Surge Protection Devices',
    imageSrc: LogoNovaris,
    altText: 'Novaris',
  },
  {
    imageCatagoryName: 'Lightning Surge Protection Devices',
    imageSrc: LogoCitel,
    altText: 'Citel',
  },
  {
    imageCatagoryName: 'Network Structure Cabling',
    imageSrc: LogoSiemon,
    altText: 'Siemon',
  },
  {
    imageCatagoryName: 'Network Structure Cabling',
    imageSrc: LogoCommscope,
    altText: 'Commscope',
  },
  {
    imageCatagoryName: 'Network Structure Cabling',
    imageSrc: LogoPrysmain,
    altText: 'Prysmain',
  },
  {
    imageCatagoryName: 'Network Structure Cabling',
    imageSrc: LogoDraka,
    altText: 'Draka',
  },
  {
    imageCatagoryName: 'Network Structure Cabling',
    imageSrc: LogoFujikura,
    altText: 'Fujikura',
  },
  {
    imageCatagoryName: 'Testing Instrument',
    imageSrc: LogoFluke,
    altText: 'Fluke',
  },
  {
    imageCatagoryName: 'Testing Instrument',
    imageSrc: LogoKyoritsu,
    altText: 'Kyoritsu',
  },
  {
    imageCatagoryName: 'Testing Instrument',
    imageSrc: LogoSanwa,
    altText: 'Sanwa',
  },
  // Add more partner objects here...
];

const PartnerSection = () => {
  const containerRef = useRef(null);
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);
  const scrollDistance = 300; // Adjust scroll distance as needed

  useEffect(() => {
    const container = containerRef.current;
    const prevButton = prevButtonRef.current;
    const nextButton = nextButtonRef.current;

    const updateButtonStates = () => {
      const atStart = container.scrollLeft === 0;
      const atEnd =
        container.scrollLeft + container.clientWidth >= container.scrollWidth;
      prevButton.disabled = atStart;
      nextButton.disabled = atEnd;
    };

    const handlePrevButtonClick = () => {
      container.scrollBy({
        left: -scrollDistance,
        behavior: 'smooth',
      });
    };

    const handleNextButtonClick = () => {
      container.scrollBy({
        left: scrollDistance,
        behavior: 'smooth',
      });
    };

    prevButton.addEventListener('click', handlePrevButtonClick);
    nextButton.addEventListener('click', handleNextButtonClick);

    updateButtonStates();
    container.addEventListener('scroll', updateButtonStates);

    return () => {
      prevButton.removeEventListener('click', handlePrevButtonClick);
      nextButton.removeEventListener('click', handleNextButtonClick);
      container.removeEventListener('scroll', updateButtonStates);
    };
  }, []);

  return (
    <div className="section-content">
      <div className="section-partner-header large-7 large-centered medium-10 small-9">
        <h2 className="section-partner-header-headline typo-section-partner-header-headline">
          Partner with Us
        </h2>
        <p className="typo-section-copy section-intro-copy">
          We value our collaboration with partners and suppliers whose
          commitment to quality and innovation enhances our ability to deliver
          exceptional products and services.
        </p>
      </div>

      <div
        id="scroll-gallery-feature-cards"
        className="my-partner gallery-align-start gallery-feature-cards"
        aria-label="Partner gallery"
        style={{ '--scroll-bar-width': '0px' }}
      >
        <div
          className="scroll-container"
          ref={containerRef} // Attach ref to the scroll container
          style={{ 'scroll-snap-type': 'none' }}
        >
          <div className="item-container">
            <ul className="card-set" role="list">
              {partners.map((partner, index) => (
                <li
                  key={index}
                  role="listitem"
                  className="gallery-item grid-item"
                >
                  <div className="card-container">
                    <div className="partner-card pn-card-17">
                      <div className="as-util-relatedlink">
                        <div className="pn-card-content pn-card-content-withfullimg">
                          <div className="pn-card-content-info">
                            <div className="pn-card-content-header">
                              <span className="dd-highlight">
                                {partner.imageCatagoryName}
                              </span>
                            </div>
                            <div className="pn-card-content-picture">
                              <picture className="partner-picture">
                                <source srcSet={partner.imageSrc} />
                                <img
                                  src={partner.imageSrc}
                                  alt={partner.altText}
                                />
                              </picture>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="paddlenav paddlenav-alpha">
          <ul className="sticky-element">
            <li className="left-item">
              <button
                ref={prevButtonRef}
                id="prevButton"
                aria-label="Previous"
                className="paddlenav-arrow paddlenav-arrow-previous"
                disabled
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
                  <path d="M21.559,12.062 L15.618,17.984 L21.5221,23.944 C22.105,24.533 22.1021,25.482 21.5131,26.065 C21.2211,26.355 20.8391,26.4999987 20.4571,26.4999987 C20.0711,26.4999987 19.6851,26.352 19.3921,26.056 L12.4351,19.034 C11.8531,18.446 11.8551,17.4999987 12.4411,16.916 L19.4411,9.938 C20.0261,9.353 20.9781,9.354 21.5621,9.941 C22.1471,10.528 22.1451,11.478 21.5591,12.062 Z"></path>
                </svg>
              </button>
            </li>
            <li className="right-item">
              <button
                ref={nextButtonRef}
                id="nextButton"
                aria-label="Next"
                className="paddlenav-arrow paddlenav-arrow-next"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
                  <path d="M23.5587,16.916 C24.1447,17.4999987 24.1467,18.446 23.5647,19.034 L16.6077,26.056 C16.3147,26.352 15.9287,26.4999987 15.5427,26.4999987 C15.1607,26.4999987 14.7787,26.355 14.4867,26.065 C13.8977,25.482 13.8947,24.533 14.4777,23.944 L20.3818,17.984 L14.4408,12.062 C13.8548,11.478 13.8528,10.5279 14.4378,9.941 C15.0218,9.354 15.9738,9.353 16.5588,9.938 L23.5588,16.916 Z"></path>
                </svg>
              </button>
            </li>
            <div className="scrim"></div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PartnerSection;
