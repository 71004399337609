import React from 'react';

const HomeVision = () => {
  return (
    <div className="modal-content-home-vision">
      <div className="feature-card-modal-content">
        <h1 className="typo-modal-tile-headline modal-headline headline">
          Vision
        </h1>
        <div className="modal-content-article">
          <div className="article-content">
            <h5 class="typo-feature-card-modal-copy feature-card-modal-copy-highlight">
              Lightning Protection System:
            </h5>
            <p className="feature-card-modal-copy typo-feature-card-modal-copy">
              To be the leading provider of advanced and reliable lightning
              protection solutions, ensuring safety and peace of mind for
              communities through innovative technology and exceptional service.
            </p>
          </div>
          <div className="article-content">
            <h5 class="typo-feature-card-modal-copy feature-card-modal-copy-highlight">
              Network Structure Cabling:
            </h5>
            <p className="feature-card-modal-copy typo-feature-card-modal-copy">
              To be the premier provider of innovative and reliable network
              cabling solutions, enabling seamless connectivity and
              technological advancement for businesses and communities
              worldwide.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeVision;
