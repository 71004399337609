import React from 'react';

const FusionSplicing = () => {
  return (
    <div className="modal-project-sector">
      <h2 className="typo-modal-tile-headline">Fusion Splicing.</h2>

      <p className="feature-card-modal-copy typo-feature-card-modal-copy">
        ZEM specializes in the fusion splicing of optical fibers, a critical
        process for establishing and maintaining high-performance fiber optic
        networks. Fusion splicing involves joining two optical fibers to create
        a continuous optical path with minimal signal loss and reflection. Below
        is a detailed breakdown of our Fusion Splicing services.
      </p>

      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Assessment and Planning
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Site Evaluation
              </h5>
              <p className="typo-solution-card-modal-copy">
                We assess the installation site or existing network to determine
                the best approach for splicing, considering factors like fiber
                type, existing infrastructure, and environmental conditions.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Project Planning
              </h5>
              <p className="typo-solution-card-modal-copy">
                Our team develops a detailed plan for the fusion splicing
                process, including the selection of appropriate tools,
                materials, and techniques to ensure optimal results.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Fusion Splicing
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Preparation
              </h5>
              <p className="typo-solution-card-modal-copy">
                We prepare the optical fibers for splicing by carefully
                stripping away the protective coating, cleaning the fibers, and
                cleaving them to ensure smooth, flat surfaces for effective
                fusion.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Splicing Process
              </h5>
              <p className="typo-solution-card-modal-copy">
                Using a fusion splicer, a specialized device that precisely
                aligns and fuses the optical fibers with an electric arc, we
                ensure low splice loss and optimal performance.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Protective Sleeves
              </h5>
              <p className="typo-solution-card-modal-copy">
                After splicing, we apply protective sleeves to the spliced
                fibers to shield them from environmental factors and mechanical
                stress, ensuring long-term reliability.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Testing and Validation
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Splice Testing
              </h5>
              <p className="typo-solution-card-modal-copy">
                We conduct tests such as Optical Time-Domain Reflectometer
                (OTDR) measurements and insertion loss tests to verify the
                quality of the splice, ensuring it meets performance standards.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Documentation
              </h5>
              <p className="typo-solution-card-modal-copy">
                We provide detailed reports on splice quality and performance,
                including test results and any relevant observations, to ensure
                transparency and compliance.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Maintenance and Troubleshooting
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Troubleshooting
              </h5>
              <p className="typo-solution-card-modal-copy">
                Our team identifies and resolves issues related to fiber
                splicing, such as high splice loss or performance degradation,
                by re-splicing or making necessary adjustments.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Maintenance
              </h5>
              <p className="typo-solution-card-modal-copy">
                We perform routine maintenance on fusion splicing equipment to
                ensure it remains in good working condition, thereby maintaining
                the quality of splicing work.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Compliance and Standards
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Industry Standards
              </h5>
              <p className="typo-solution-card-modal-copy">
                We adhere to industry standards and best practices for fusion
                splicing, such as those set by organizations like the
                International Electrotechnical Commission (IEC) and the
                Telecommunications Industry Association (TIA).
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Regulatory Compliance
              </h5>
              <p className="typo-solution-card-modal-copy">
                We ensure that all work complies with local regulations and
                safety standards, providing reliable and safe fiber optic
                network installations.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-content-solution">
        <div className="content-wrap">
          <h5 className="feature-card-modal-copy-highlight typo-feature-card-modal-copy">
            Training and Expertise
          </h5>

          <ul>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Technical Expertise
              </h5>
              <p className="typo-solution-card-modal-copy">
                Our team possesses in-depth knowledge of fiber optic technology,
                fusion splicing techniques, and the operation of splicing
                equipment, ensuring high-quality work.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Certification
              </h5>
              <p className="typo-solution-card-modal-copy">
                Relevant certifications from fiber optic training organizations
                or manufacturers demonstrate our team’s expertise and adherence
                to industry standards.
              </p>
            </li>
            <li>
              <h5 className="typo-solution-card-modal-copy-highlight">
                Experience
              </h5>
              <p className="typo-solution-card-modal-copy">
                Practical experience with various types of optical fibers and
                splicing scenarios is crucial to delivering high-quality
                splicing work, ensuring reliable and low-loss connections.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <p
        className="feature-card-modal-copy typo-feature-card-modal-copy"
        style={{ marginTop: '20px' }}
      >
        <strong>Summary:</strong> At ZEM, we play a vital role in the creation
        and maintenance of reliable fiber optic networks. Our expertise in
        fusion splicing ensures that optical fibers are joined seamlessly,
        providing high-performance and low-loss connections essential for modern
        telecommunications and data networks.
      </p>
    </div>
  );
};

export default FusionSplicing;
