import React from 'react';
import './Contact.css';
import ContactHeroSmall from './images/hero-banner-homepage.image.small_2x.jpg';
import ContactHeroLarge from './images/hero-banner-homepage.image.large_2x.jpg';
import googleMap from './images/app-googlemaps.jpg';
import wazeMap from './images/app-waze.jpg';

export default function ContactUs() {
  const googleClick = () => {
    window.open('https://maps.app.goo.gl/WsRt1XY7SCFPZauJ6', '_blank');
  };

  const wazeClick = () => {
    window.open(
      'https://www.waze.com/en/live-map/directions/my/selangor/pj/zem-engineering-(m)-sdn-bhd?place=ChIJVXEEzp9OzDERI5A3J2-PMJs',
      '_blank',
    );
  };

  return (
    <section className="as-container-column as-columns--1up as-banner as-banner--top">
      <div className="column-item">
        <div className="as-banner-cont">
          <div className="as-banner-image as-banner-image--top">
            <img
              sizes="(min-width:735px) 735w, 100vw"
              src={ContactHeroLarge}
              alt="Call us now"
              srcSet={`${ContactHeroSmall} 735w, ${ContactHeroLarge} 1440w`}
            />
          </div>
        </div>
      </div>

      <section
        className="section section-hero background-alt"
        data-anim-scroll-group="Hero"
        data-component-list="Hero"
      >
        <div
          className="section-content row"
          data-analytics-section-engagement="name:hero"
        >
          <div className="hero-header large-centered">
            <h2 className="section-headline typo-hero-headline">
              Ready to Work, Let's Chat.
            </h2>
            <h1 className="visuallyhidden">Ready to Work, Let's Chat.</h1>
            <p className="typo-hero-intro">
              Our team of experts is ready to collaborate with you every step of
              the way, from initial consultation to implementation.
            </p>
          </div>
        </div>
      </section>

      <section className="section-contact as-container-column as-columns--1up as-columns-bg contact-zem-bg">
        <div className="column-item">
          <div className="content-banner-row">
            <div className="grid as-tile-wrapper as-tile--1up as-fluid">
              <div className="grid-item large-span-12 medium-span-12 small-span-12">
                <div className="as-tile as-tile-rounded">
                  <div className="as-tile-content as-center">
                    <h2 className="typo-headline-reduced">Call us now</h2>
                    <div className="as-richtext typo-section-copy">
                      <p>
                        We’re available by phone during business hours, Monday
                        to Friday, from 8:30 AM to 5:00 PM (GMT). If you’d like
                        to discuss your project or inquiry directly, please
                        don’t hesitate to call us.
                      </p>
                    </div>
                    <div
                      style={{
                        fontSize: '19px',
                        padding: '40px 30px',
                        marginTop: '50px',
                        background: 'var(--global-card-background-color)',
                        borderRadius: 'var(--global-card-corner-radius)',
                      }}
                    >
                      <p>
                        <strong>Let’s Connect: We Value Your Voice</strong>
                      </p>
                      <div className="contact-container">
                        Office number:{' '}
                        <a
                          href="tel:+60378867936"
                          style={{
                            color: 'var(--zem-color)',
                            fontWeight: '500',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                          }}
                        >
                          +603-7886 7936
                        </a>
                      </div>
                      <div className="contact-container">
                        Fax number:{' '}
                        <a
                          href="fax:+60378868936"
                          style={{
                            color: 'var(--zem-color)',
                            fontWeight: '500',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                          }}
                        >
                          +603-7886 8936
                        </a>
                      </div>
                      <div className="contact-container">
                        Email Address:{' '}
                        <a
                          href="mailto:zem@zem.com.my"
                          style={{
                            color: 'var(--zem-color)',
                            fontWeight: '500',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                          }}
                        >
                          zem @ zem.com.my
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid as-tile-wrapper as-tile--1up as-fluid">
              <div className="grid-item large-span-12 medium-span-12 small-span-12">
                <div className="as-tile as-tile-rounded">
                  <div className="as-tile-content as-center">
                    <h2 className="typo-headline-reduced">Find us</h2>
                    <div className="as-richtext typo-section-copy">
                      <p>
                        Easily find us using Google Maps or Waze for seamless
                        navigation.
                      </p>
                    </div>
                    <div className="location-map-column-grid">
                      <div className="map-google">
                        <div className="typo-intro-elevated">Google Maps</div>
                        <div className="map-app">
                          <button
                            className="google icon-wrapper typo-body analytics-exitlink whileTap"
                            data-analytics-event="link.component_click"
                            data-analytics-link-component_type="tile"
                            data-analytics-link-component_name="Visit ZEM Engineering by Google Maps"
                            data-analytics-link-url="#"
                            onClick={googleClick}
                            rel="nofollow"
                          >
                            <div className="app-icon">
                              <img src={googleMap} alt="Google Maps" />
                            </div>
                            <span
                              className="link-visit"
                              aria-label="Visit ZEM Engineering by Google Maps"
                            >
                              Directions
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="map-waze">
                        <div className="typo-intro-elevated">Waze</div>
                        <div className="map-app">
                          <button
                            className="waze icon-wrapper typo-body analytics-exitlink"
                            data-analytics-event="link.component_click"
                            data-analytics-link-component_type="tile"
                            data-analytics-link-component_name="Visit ZEM Engineering by Waze"
                            data-analytics-link-url="#"
                            onClick={wazeClick}
                            rel="nofollow"
                          >
                            <div className="app-icon">
                              <img src={wazeMap} alt="Waze" />
                            </div>
                            <span
                              className="link-visit"
                              aria-label="Visit ZEM Engineering by Waze"
                            >
                              Directions
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: '19px',
                        padding: '50px 30px',
                        marginTop: '20px',
                        background: 'var(--global-card-background-color)',
                        borderRadius: 'var(--global-card-corner-radius)',
                      }}
                    >
                      <p>
                        <strong>Office Address</strong>
                      </p>
                      <p>
                        85G, Pusat Dagangan NZX, Jalan PJU 1A/41B, Ara Jaya,
                        47301 Petaling Jaya, Selangor Darul Ehsan, Malaysia.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}
