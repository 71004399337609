import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import LightnightSurgeProtectionDevices from './LightnightSurgeProtectionDevices';
import LPSHealthCheck from './LPSHealthCheck';
import CablingTesting from './CablingTesting';
import FusionSplicing from './FusionSplicing';

import imgLightningSurgeSmall from './images/solution-a_small.jpg';
import imgLightningSurgeSmall2x from './images/solution-a_small_2x.jpg';
import imgLightningSurgeMedium from './images/solution-a_medium.jpg';
import imgLightningSurgeMedium2x from './images/solution-a_medium_2x.jpg';
import imgLightningSurgeLarge from './images/solution-a_large.jpg';
import imgLightningSurgeLarge2x from './images/solution-a_large_2x.jpg';

import imgLpsHealthCheckSmall from './images/solution-b_small.jpg';
import imgLpsHealthCheckSmall2x from './images/solution-b_small_2x.jpg';
import imgLpsHealthCheckMedium from './images/solution-b_medium.jpg';
import imgLpsHealthCheckMedium2x from './images/solution-b_medium_2x.jpg';
import imgLpsHealthCheckLarge from './images/solution-b_large.jpg';
import imgLpsHealthCheckLarge2x from './images/solution-b_large_2x.jpg';

import imgCablingTestSmall from './images/solution-c_small.jpg';
import imgCablingTestSmall2x from './images/solution-c_small_2x.jpg';
import imgCablingTestMedium from './images/solution-c_medium.jpg';
import imgCablingTestMedium2x from './images/solution-c_medium_2x.jpg';
import imgCablingTestLarge from './images/solution-c_large.jpg';
import imgCablingTestLarge2x from './images/solution-c_large_2x.jpg';

import imgFusionSplicingSmall from './images/solution-d_small.jpg';
import imgFusionSplicingSmall2x from './images/solution-d_small_2x.jpg';
import imgFusionSplicingMedium from './images/solution-d_medium.jpg';
import imgFusionSplicingMedium2x from './images/solution-d_medium_2x.jpg';
import imgFusionSplicingLarge from './images/solution-d_large.jpg';
import imgFusionSplicingLarge2x from './images/solution-d_large_2x.jpg';

const ListSolution = [
  {
    title: 'Lightning Surge Protection Devices',
    label: 'Airport',
    component: <LightnightSurgeProtectionDevices />,
  },
  {
    title: 'LPS Health Check',
    label: 'Commercial & Office',
    component: <LPSHealthCheck />,
  },
  {
    title: 'Cabling Testing & Certification',
    label: 'International School',
    component: <CablingTesting />,
  },
  {
    title: 'Fusion Splicing',
    label: 'Railway',
    component: <FusionSplicing />,
  },
];

const LightningProtectionCard = ({ onOpen }) => (
  <div className="grid-item lightning-protection half-width">
    <div
      className="tile tile-rounded"
      data-analytics-section-engagement="name:Lightning Surge Protection Devices"
    >
      <div className="tile-content">
        <div className="copy-wrapper">
          <p className="tile-eyebrow typo-product-tile-eyebrow">
            Earthing System, Lightning Protection System
          </p>
          <h3 className="tile-headline typo-product-tile-headline">
            & Lightning Surge Protection Devices
          </h3>
          <div className="tile-cta">
            <button
              onClick={() => onOpen(ListSolution[0].component)}
              aria-label="Learn more"
              className="button button-base button-learn"
            >
              <span className="icon-copy">Learn more</span>
              <span className="icon icon-after more">
                <svg
                  class="tds-icon tds-icon-chevron-small-90 tds-icon--small"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="currentColor"
                    d="M4.975 2.75a.748.748 0 0 1 1.281-.53l5.25 5.264a.75.75 0 0 1 0 1.06L6.283 13.78a.75.75 0 1 1-1.062-1.06l4.695-4.706L5.194 3.28a.75.75 0 0 1-.219-.53"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div className="picture-wrapper">
          <picture
            id="overview-lightning-protection"
            className="overview-lightning-protection name loaded"
            data-lazy=""
            data-picture-loaded=""
          >
            <source
              srcSet={`${imgLightningSurgeSmall} 1x, ${imgLightningSurgeSmall2x} 2x`}
              media="(max-width:734px)"
            />
            <source
              srcSet={`${imgLightningSurgeMedium} 1x, ${imgLightningSurgeMedium2x} 2x`}
              media="(max-width:1068px)"
            />
            <source
              srcSet={`${imgLightningSurgeLarge} 1x, ${imgLightningSurgeLarge2x} 2x`}
              media="(min-width:0px)"
            />
            <img src={imgLightningSurgeLarge} onload="" alt="alt" />
          </picture>
          <noscript>
            <picture class="overview-lightning-protection name">
              <source
                srcSet={`${imgLightningSurgeSmall} 1x, ${imgLightningSurgeSmall2x} 2x`}
                media="(max-width:734px)"
              />
              <source
                srcSet={`${imgLightningSurgeMedium} 1x, ${imgLightningSurgeMedium2x} 2x`}
                media="(max-width:1068px)"
              />
              <source
                srcSet={`${imgLightningSurgeLarge} 1x, ${imgLightningSurgeLarge2x} 2x`}
                media="(min-width:0px)"
              />
              <img src={imgLightningSurgeLarge} alt="alt" />
            </picture>
          </noscript>
        </div>
      </div>
    </div>
  </div>
);

const LPSHealthCard = ({ onOpen }) => (
  <div className="grid-item lps-health half-width">
    <div
      className="tile tile-rounded"
      data-analytics-section-engagement="name:LPS Health Check"
    >
      <div className="tile-content">
        <div className="copy-wrapper">
          <p className="tile-eyebrow typo-product-tile-eyebrow">
            Earthing & Lightning Protection
          </p>
          <h3 className="tile-headline typo-product-tile-headline">
            LPS Health Check (Audit Services)
          </h3>
          <div className="tile-cta">
            <button
              onClick={() => onOpen(ListSolution[1].component)}
              aria-label="Learn more"
              className="button button-base button-learn"
            >
              <span className="icon-copy">Learn more</span>
              <span className="icon icon-after more">
                <svg
                  class="tds-icon tds-icon-chevron-small-90 tds-icon--small"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="currentColor"
                    d="M4.975 2.75a.748.748 0 0 1 1.281-.53l5.25 5.264a.75.75 0 0 1 0 1.06L6.283 13.78a.75.75 0 1 1-1.062-1.06l4.695-4.706L5.194 3.28a.75.75 0 0 1-.219-.53"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div className="picture-wrapper">
          <picture
            id="overview-lps-health"
            className="overview-lps-health name loaded"
            data-lazy=""
            data-picture-loaded=""
          >
            <source
              srcSet={`${imgLpsHealthCheckSmall} 1x, ${imgLpsHealthCheckSmall2x} 2x`}
              media="(max-width:734px)"
            />
            <source
              srcSet={`${imgLpsHealthCheckMedium} 1x, ${imgLpsHealthCheckMedium2x} 2x`}
              media="(max-width:1068px)"
            />
            <source
              srcSet={`${imgLpsHealthCheckLarge} 1x, ${imgLpsHealthCheckLarge2x} 2x`}
              media="(min-width:0px)"
            />
            <img src={imgLpsHealthCheckLarge} onload="" alt="alt" />
          </picture>
          <noscript>
            <picture class="overview-lightning-protection name">
              <source
                srcSet={`${imgLpsHealthCheckSmall} 1x, ${imgLpsHealthCheckSmall2x} 2x`}
                media="(max-width:734px)"
              />
              <source
                srcSet={`${imgLpsHealthCheckMedium} 1x, ${imgLpsHealthCheckMedium2x} 2x`}
                media="(max-width:1068px)"
              />
              <source
                srcSet={`${imgLpsHealthCheckLarge} 1x, ${imgLpsHealthCheckLarge2x} 2x`}
                media="(min-width:0px)"
              />
              <img src={imgLpsHealthCheckLarge} alt="alt" />
            </picture>
          </noscript>
        </div>
      </div>
    </div>
  </div>
);

const CablingTestingCard = ({ onOpen }) => (
  <div className="grid-item cabling-certification half-width">
    <div
      className="tile tile-rounded"
      data-analytics-section-engagement="name:Cabling Testing & Certification"
    >
      <div className="tile-content">
        <div className="copy-wrapper">
          <p className="tile-eyebrow typo-product-tile-eyebrow">
            Network Structure Cabling
          </p>
          <h3 className="tile-headline typo-product-tile-headline">
            Cabling Testing & Certification
          </h3>
          <div className="tile-cta">
            <button
              onClick={() => onOpen(ListSolution[2].component)}
              aria-label="Learn more"
              className="button button-base button-learn"
            >
              <span className="icon-copy">Learn more</span>
              <span className="icon icon-after more">
                <svg
                  class="tds-icon tds-icon-chevron-small-90 tds-icon--small"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="currentColor"
                    d="M4.975 2.75a.748.748 0 0 1 1.281-.53l5.25 5.264a.75.75 0 0 1 0 1.06L6.283 13.78a.75.75 0 1 1-1.062-1.06l4.695-4.706L5.194 3.28a.75.75 0 0 1-.219-.53"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div className="picture-wrapper">
          <picture
            id="overview-cabling-testing"
            className="overview-lightning-protection name loaded"
            data-lazy=""
            data-picture-loaded=""
          >
            <source
              srcSet={`${imgCablingTestSmall} 1x, ${imgCablingTestSmall2x} 2x`}
              media="(max-width:734px)"
            />
            <source
              srcSet={`${imgCablingTestMedium} 1x, ${imgCablingTestMedium2x} 2x`}
              media="(max-width:1068px)"
            />
            <source
              srcSet={`${imgCablingTestLarge} 1x, ${imgCablingTestLarge2x} 2x`}
              media="(min-width:0px)"
            />
            <img src={imgCablingTestLarge} onload="" alt="alt" />
          </picture>
          <noscript>
            <picture class="overview-lightning-protection name">
              <source
                srcSet={`${imgCablingTestSmall} 1x, ${imgCablingTestSmall2x} 2x`}
                media="(max-width:734px)"
              />
              <source
                srcSet={`${imgCablingTestMedium} 1x, ${imgCablingTestMedium2x} 2x`}
                media="(max-width:1068px)"
              />
              <source
                srcSet={`${imgCablingTestLarge} 1x, ${imgCablingTestLarge2x} 2x`}
                media="(min-width:0px)"
              />
              <img src={imgCablingTestLarge} alt="alt" />
            </picture>
          </noscript>
        </div>
      </div>
    </div>
  </div>
);

const FusionSplicingCard = ({ onOpen }) => (
  <div className="grid-item fusion-splicing half-width">
    <div
      className="tile tile-rounded"
      data-analytics-section-engagement="name:Fusion Splicing"
    >
      <div className="tile-content">
        <div className="copy-wrapper">
          <p className="tile-eyebrow typo-product-tile-eyebrow">
            Network Structure Cabling
          </p>
          <h3 className="tile-headline typo-product-tile-headline">
            Fusion Splicing
          </h3>
          <div className="tile-cta">
            <button
              onClick={() => onOpen(ListSolution[3].component)}
              aria-label="Learn more"
              className="button button-base button-learn"
            >
              <span className="icon-copy">Learn more</span>
              <span className="icon icon-after more">
                <svg
                  class="tds-icon tds-icon-chevron-small-90 tds-icon--small"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="currentColor"
                    d="M4.975 2.75a.748.748 0 0 1 1.281-.53l5.25 5.264a.75.75 0 0 1 0 1.06L6.283 13.78a.75.75 0 1 1-1.062-1.06l4.695-4.706L5.194 3.28a.75.75 0 0 1-.219-.53"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div className="picture-wrapper">
          <picture
            id="overview-usion-splicing"
            className="overview-fusion-splicing name loaded"
            data-lazy=""
            data-picture-loaded=""
          >
            <source
              srcSet={`${imgFusionSplicingSmall} 1x, ${imgFusionSplicingSmall2x} 2x`}
              media="(max-width:734px)"
            />
            <source
              srcSet={`${imgFusionSplicingMedium} 1x, ${imgFusionSplicingMedium2x} 2x`}
              media="(max-width:1068px)"
            />
            <source
              srcSet={`${imgFusionSplicingLarge} 1x, ${imgFusionSplicingLarge2x} 2x`}
              media="(min-width:0px)"
            />
            <img src={imgFusionSplicingLarge} onload="" alt="alt" />
          </picture>
          <noscript>
            <picture class="overview-lightning-protection name">
              <source
                srcSet={`${imgFusionSplicingSmall} 1x, ${imgFusionSplicingSmall2x} 2x`}
                media="(max-width:734px)"
              />
              <source
                srcSet={`${imgFusionSplicingMedium} 1x, ${imgFusionSplicingMedium2x} 2x`}
                media="(max-width:1068px)"
              />
              <source
                srcSet={`${imgFusionSplicingLarge} 1x, ${imgFusionSplicingLarge2x} 2x`}
                media="(min-width:0px)"
              />
              <img src={imgFusionSplicingLarge} alt="alt" />
            </picture>
          </noscript>
        </div>
      </div>
    </div>
  </div>
);

const SolutionsGrid = () => {
  const [activeComponent, setActiveComponent] = useState(null);

  const handleShowComponent = (component) => {
    setActiveComponent(component);
  };

  const handleClose = () => setActiveComponent(null);

  return (
    <div className="section-content row">
      <div className="grid">
        <LightningProtectionCard onOpen={handleShowComponent} />
        <LPSHealthCard onOpen={handleShowComponent} />
        <CablingTestingCard onOpen={handleShowComponent} />
        <FusionSplicingCard onOpen={handleShowComponent} />
      </div>
      {activeComponent && (
        <Modal
          show={true}
          onHide={handleClose}
          className="modal-page-overlay feature-card-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="modal-content-container">
            <Modal.Body className="modal-content-wrapper">
              <div className="feature-card-modal-content">
                {activeComponent}
              </div>
            </Modal.Body>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default SolutionsGrid;
